import React, { ReactNode } from 'react';

import { ButtonType } from '../../types/button';
import {
  ContactBannerCardButtons,
  ContactBannerCardHeader,
  ContactBannerCardInformation,
  ContactBannerCardSubText,
  ContactBannerCardWrapper,
  ContactBannerContainer,
  ContactBannerHeader,
  ContactBannerImageContainer,
} from './ContactBanner.style';

type CardsType = {
  cardBgColor: string;
  cardHeaderText: string;
  cardSubText: ReactNode;
  buttons: ButtonType[];
  image: ReactNode;
  isReverse?: boolean;
};

export type ContactBannerData = {
  bgColor: string;
  headerText: ReactNode;
  cards: CardsType[];
};

type Props = {
  data: ContactBannerData;
};

const ContactBanner = ({ data }: Props) => {
  return (
    <ContactBannerContainer $backgroundColor={data.bgColor}>
      <ContactBannerHeader>{data.headerText}</ContactBannerHeader>
      {data.cards.map((item) => (
        <ContactBannerCardWrapper
          key={item.cardHeaderText}
          $backgroundColor={item.cardBgColor}
          $isReverse={item.isReverse}
        >
          <>
            <ContactBannerCardInformation>
              <ContactBannerCardHeader>
                {item.cardHeaderText}
              </ContactBannerCardHeader>
              <ContactBannerCardSubText>
                {item.cardSubText}
              </ContactBannerCardSubText>
              {item.buttons && (
                <>
                  {item.buttons.map((btn: ButtonType) => (
                    <ContactBannerCardButtons
                      key={btn.label}
                      // @ts-ignore
                      to={btn.url}
                    >
                      {btn.label}
                    </ContactBannerCardButtons>
                  ))}
                </>
              )}
            </ContactBannerCardInformation>
            <ContactBannerImageContainer
              $isReverse={item.isReverse}
              data-testid="contact-banner-image"
            >
              {item.image}
            </ContactBannerImageContainer>
          </>
        </ContactBannerCardWrapper>
      ))}
    </ContactBannerContainer>
  );
};

export default ContactBanner;
