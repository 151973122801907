import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import config from '../../config';
import usePetChanges from '../../hooks/usePetChanges';
import InsuranceCardImpactV2, {
  InsuranceCardImpactV2Data,
} from '../insuranceCard/InsuranceCardImpactV2';
import MobileInsuranceCardImpactV2 from '../insuranceCard/MobileInsuranceCardImpactV2';
import {
  CarSvg,
  CtaButtonsContainer,
  CtaContainer,
  HouseSvg,
  PetSvg,
} from './CtaBannerImpactV2.style';

type Props = {
  isErrorPage?: boolean;
  isHomepage?: boolean;
  isRAFPage?: boolean;
};

const ctaData: InsuranceCardImpactV2Data[] = [
  {
    heading: 'Home insurance',
    testId: 'home',
    icon: <HouseSvg />,
    ctaUrl: config.HOME_URL,
    ctaHeading: 'Get a quote',
    link1text: 'Find out more',
    link1Url: '/home-insurance',
    link2text: 'Retrieve quote',
    link2Url: config.HOME_LOGIN_URL,
  },
  {
    heading: 'Car insurance',
    testId: 'car',
    icon: <CarSvg />,
    ctaUrl: config.CAR_URL,
    ctaHeading: 'Get a quote',
    link1text: 'Find out more',
    link1Url: '/car-insurance',
    link2text: 'Retrieve quote',
    link2Url: config.CAR_RETRIEVE_URL,
  },
];

const CtaBannerImpactV2 = ({ isErrorPage, isHomepage, isRAFPage }: Props) => {
  const displayPetChanges = usePetChanges();
  const { isMobile } = useBreakpoint();

  const petButton = displayPetChanges
    ? {
        heading: 'Pet insurance',
        testId: 'pet',
        icon: <PetSvg />,
        ctaHeading: 'Get a quote',
        ctaUrl: config.PET_URL,
        link1text: 'Find out more',
        link1Url: '/pet-insurance',
        link2text: 'Retrieve quote',
        link2Url: config.HOME_LOGIN_URL,
      }
    : {
        heading: 'Pet insurance',
        testId: 'pet',
        icon: <PetSvg />,
        ctaHeading: 'Interested?',
        ctaUrl: '/pet-insurance/renewal-date',
        link1text: "Share your pet's renewal date with us",
        link1Url: '/pet-insurance/renewal-date',
        isComingSoon: true,
      };
  return (
    <CtaContainer>
      <CtaButtonsContainer
        $isErrorPage={isErrorPage}
        $isHomepage={isHomepage}
        $isRAFPage={isRAFPage}
      >
        {[...ctaData, petButton].map((card: InsuranceCardImpactV2Data) => {
          return (
            <div key={`carousel-${card.heading}-InsuranceCard`}>
              {isMobile ? (
                <MobileInsuranceCardImpactV2 data={card} />
              ) : (
                <InsuranceCardImpactV2 data={card} />
              )}
            </div>
          );
        })}
      </CtaButtonsContainer>
    </CtaContainer>
  );
};

export default CtaBannerImpactV2;
