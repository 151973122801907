import React from 'react';

import { HeadFC } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';
import scrollTo from 'gatsby-plugin-smoothscroll';

import ImpactV2HeaderBanner from '../components/headerBanner/HeaderBannerV2';
import {
  CrossIcon,
  TickIcon,
} from '../components/homeEmergencyTable/HomeEmergencyTable.style';
import MultiTabTable, {
  MultiTabTableData,
} from '../components/multiTabTable/MultiTabTable';
import ProductIconBanner, {
  IconsBannerData,
} from '../components/petIconsBanner/PetIconsBanner';
import {
  IconBannerHeader,
  IconBannerMoreInfo,
  IconBannerText,
} from '../components/petIconsBanner/PetIconsBanner.style';
import {
  ProductTableLabel,
  ProductTableValue,
} from '../components/petProductTable/PetProductTable.style';
import ProductBanner from '../components/productBanner/ProductBanner';
import { ProductBannerPolicyDocumentsDataType } from '../components/productBannerPolicyDocuments/ProductBannerPolicyDocuments';
import ReviewSection from '../components/reviewBanner/ReviewSectionNoImage';
import ReviewCard from '../components/reviewCard/ReviewCard';
import Shape from '../components/Shape';
import SlantedBackground from '../components/slantedBackground/SlantedBackground';
import config from '../config';
import useDisclaimer from '../hooks/useDisclaimer';
import {
  CarSvg,
  ClockSvg,
  CoinsSvg,
  colors,
  CourtSvg,
  HeroBannerImageContainer,
  KeySvg,
  OverflowContainer,
  PhoneSvg,
  PolicyExpertBronzeIcon,
  PolicyExpertGoldIcon,
  PolicyExpertSilverIcon,
  ProductBannerContainer,
  ProductPageHeaderBannerTitle,
  ScaleSvg,
  SemiBoldSpan,
  TriangleSvg,
} from '../styles/sharedStyled';
import { ReviewData } from '../types/reviews';
import {
  BikeSvg,
  BoilerPurpleIcon,
  BoilerSvg,
  BurstpipesSvg,
  CameraSvg,
  CardsSvg,
  CompareChartIcon,
  GlobeSvg,
  HomeInsuranceHeaderText,
  HouseSvg,
  MobilePhoneSvg,
  OneExcessIcon,
  OnlinePortalPurpleIcon,
  PeopleSvg,
  Rated1Icon,
  ScrewdriverSvg,
  SecurityCoverSvg,
  TimerSpeedIcon,
  ToiletSvg,
  TvSvg,
} from './home-insurance.style';

const multiTabTableData: MultiTabTableData = {
  headerText: 'Create cover that suits you',
  subText:
    'Select exactly what you need and we’ll tailor the perfect policy for you.',
  tabs: [
    {
      title: 'Home emergency',
      extras: [
        {
          icon: <ClockSvg />,
          text: 'Claims line open 24/7, all year round',
        },
        {
          icon: <BurstpipesSvg />,
          text: 'Burst pipes, plumbing and drainage problems',
        },
        {
          icon: <SecurityCoverSvg />,
          text: 'Security cover (if a window gets smashed, for example) ',
        },
        {
          icon: <BoilerSvg />,
          text: 'Boiler breakdown, including heating and hot water failures',
        },
        {
          icon: <ToiletSvg />,
          text: 'Helping with your toilet if it’s blocked or won’t flush.',
        },
      ],
      link: '/home-insurance/policy-documents/home-emergency-comparison/',
      information: (
        <>
          Home Emergency cover is there for you if you're experiencing{' '}
          <span>an emergency</span> - a <span>sudden</span>,{' '}
          <span>unexpected</span> event which leaves your home{' '}
          <span>unsafe</span> or <span>not secure</span>, or which leaves you{' '}
          <span>without essential services</span>, like heating or a working
          toilet.
          <br />
          <br />
          If you have Home Emergency Cover, we'll arrange for a contractor to
          come and put in place a <span>temporary repair</span> during the
          emergency, to keep you and your home safe.
        </>
      ),
    },
    {
      title: 'Legal expenses',
      extras: [
        {
          icon: <PhoneSvg />,
          text: 'A helpline service with trained professionals to help you with private legal issues',
        },
        {
          icon: <CourtSvg />,
          text: 'Compensation if you’ve lost earnings due to Jury Service',
        },
        {
          icon: <ScaleSvg />,
          text: "Help to claim compensation if you’re injured and it's not your fault",
        },
        {
          icon: <HouseSvg />,
          text: 'Protection of your legal rights as a home-owner (like boundary or neighbour disputes)',
        },
        {
          icon: <CourtSvg />,
          text: 'Resolving disputes over goods or services you’ve bought, including holidays',
        },
        {
          icon: <CardsSvg />,
          text: 'Support with debt, court judgements and your credit rating if you’re a victim of identify theft',
        },
      ],
    },
    {
      title: 'Key cover',
      extras: [
        {
          icon: <CoinsSvg />,
          text: '£1500 of cover',
        },
        {
          icon: <ClockSvg />,
          text: '365/24/7 emergency helpline',
        },
        {
          icon: <KeySvg />,
          text: 'Cover for locksmith charges, replacement keys and locks, reprogramming immobilisers and travel costs',
        },
        {
          icon: <CoinsSvg />,
          text: 'A reward for the finder of your lost keys',
        },
      ],
    },
    {
      title: 'Cycle cover',
      extras: [
        {
          icon: <BikeSvg />,
          text: 'Cover for bikes stolen if you’re away from home',
        },
        {
          icon: <CoinsSvg />,
          text: 'Higher limits available for more expensive bikes',
        },
        {
          icon: <SecurityCoverSvg />,
          text: 'Payment if your bike is damaged whilst locked up outside',
        },
        {
          icon: <GlobeSvg />,
          text: 'Cover throughout the UK and worldwide',
        },
      ],
    },
    {
      title: 'Accidental damage',
      extras: [
        {
          icon: <TriangleSvg />,
          text: 'Protection from DIY accidents (like hammering through a pipe)',
        },
        {
          icon: <PeopleSvg />,
          text: 'Compensation for damage caused by children',
        },
        {
          icon: <ScrewdriverSvg />,
          text: 'Repair or replace fixtures and fittings like sinks or kitchen work surfaces',
        },
        {
          icon: <BurstpipesSvg />,
          text: 'Cover for spillages particularly on carpets and soft furnishings',
        },
        {
          icon: <TvSvg />,
          text: 'Cover if you accidentally break something valuable (like cracking a TV screen when repositioning it)',
        },
        {
          icon: <MobilePhoneSvg />,
          text: 'Repair or replace damaged computer equipment like laptops or tablets',
        },
      ],
    },
    {
      title: 'Away from home',
      extras: [
        {
          icon: <CameraSvg />,
          text: 'Cover for items worth less than £2000 that you take outside your home',
        },
        {
          icon: <MobilePhoneSvg />,
          text: 'Cover for loss or theft of mobile phones, tablets or laptops',
        },
        {
          icon: <CoinsSvg />,
          text: 'Get repayed for money stolen from you, including foreign currency',
        },
        {
          icon: <CarSvg />,
          text: "Items stolen from an unattended vehicle (as long as it's locked)",
        },
      ],
    },
  ],
};

const homeInsurancePageDisclaimer = (
  <>
    <br />
    *The number of customers who reported savings when they switched their home
    insurance to Policy Expert, based on 548 survey responses carried out by
    QMetric Group Limited in May 2024.
    <br />
    **Policy Expert is rated the number 1 home insurance provider in the UK on
    independent review website – Reviewcentre.com. Based on 58,736 customer
    reviews and a 96.6% recommendation rating, June 2024.
  </>
);

const reviewsTitle = { headerText: 'People love what we do...' };

const policyDocuments: ProductBannerPolicyDocumentsDataType = {
  headerTitle: 'Policy wording',
  headerText:
    'Our policy wording has been reviewed by the Plain English Campaign, who champion policies that are crystal clear.',
  button: {
    label: 'Policy wording',
    url: '/home-insurance/policy-documents',
  },
};

const HomeInsuranceReviewsData: ReviewData[] = [
  {
    title: 'Brilliant customer service',
    author: 'Mr P Windows',
    date: ' 20/03/2024',
    review:
      'Good value policy with great customer service. Unfortunately had a claim last year when a lorry damaged our property, but Policy Expert quickly sorted it out.',
  },
  {
    title: 'No problems with claims',
    author: 'Joan Malyon',
    date: '20/03/2024',
    review: 'Great company - paid out my carpet claim with no problem.',
  },
  {
    title: 'Efficient claims process',
    author: 'Eileen B',
    date: '03/04/2024',
    review:
      'I made one claim for damage to a shower room a few years back which was dealt with efficiently. This year I auto renewed as I could not find a more competitive deal elsewhere. When I have contacted the staff they have always been very knowledgeable, helpful and polite.',
  },
  {
    title: 'Outstanding service',
    author: 'Ruth Lambert',
    date: '13/03/2024',
    review:
      'We recently had a burst in the boiler in the loft affecting a bedroom and lounge. The response from Policy Expert was outstanding... Excellent service from start to finish. Highly recommended.',
  },
];

const HomeInsuranceReviews: JSX.Element[] = [];
HomeInsuranceReviewsData.map((item) => {
  HomeInsuranceReviews.push(
    <ReviewCard key={`carousel-${item.review}`} data={item} />
  );
});

const HomeInsurancePage: React.FC = () => {
  const { isMobile, isDesktop } = useBreakpoint();

  useDisclaimer(homeInsurancePageDisclaimer);

  const productBannerData = {
    productTableData: {
      tableHeaderIcons: [
        <PolicyExpertBronzeIcon key="PolicyExpertBronzeIcon" />,
        <PolicyExpertSilverIcon key="PolicyExpertSilverIcon" />,
        <PolicyExpertGoldIcon key="PolicyExpertGoldIcon" />,
      ],
      tableContent: [
        {
          productRows: [
            {
              label: (
                <ProductTableLabel>
                  Buildings insurance sum covered
                </ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Buildings insurance sum Bronze">
                  £600,000
                </ProductTableValue>,
                <ProductTableValue key="Buildings insurance sum Silver">
                  £1,000,000
                </ProductTableValue>,
                <ProductTableValue key="Buildings insurance sum Gold">
                  £1,000,000
                </ProductTableValue>,
              ],
            },
            {
              label: (
                <ProductTableLabel>
                  Contents insurance sum covered
                </ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Contents insurance sum Silver">
                  £60,000
                </ProductTableValue>,
                <ProductTableValue key="Contents insurance sum Silver">
                  £75,000
                </ProductTableValue>,
                <ProductTableValue key="Contents insurance sum Gold">
                  £100,000
                </ProductTableValue>,
              ],
            },
            {
              label: (
                <ProductTableLabel>
                  New for old cover as standard
                </ProductTableLabel>
              ),
              values: [
                <TickIcon key="New for old Bronze" />,
                <TickIcon key="New for old Silver" />,
                <TickIcon key="New for old Gold" />,
              ],
            },
            {
              label: (
                <ProductTableLabel>
                  Alternative accommodation (buildings)
                </ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Alternative accommodation buildings Bronze">
                  Up to{isMobile ? <br /> : ' '}£75,000
                </ProductTableValue>,
                <ProductTableValue key="Alternative accommodation buildings Silver">
                  Up to{isMobile ? <br /> : ' '}£100,000
                </ProductTableValue>,
                <ProductTableValue key="Alternative accommodation buildings Gold">
                  Up to{isMobile ? <br /> : ' '}£150,000
                </ProductTableValue>,
              ],
            },

            {
              label: (
                <ProductTableLabel>
                  Alternative accommodation (contents)
                </ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Alternative accommodation contents Bronze">
                  {config.SHOW_UPDATED_DOCS === 'true' ? '£15,000' : '£10,000'}
                </ProductTableValue>,
                <ProductTableValue key="Alternative accommodation contents Silver">
                  {config.SHOW_UPDATED_DOCS === 'true' ? '£15,000' : '£10,000'}
                </ProductTableValue>,
                <ProductTableValue key="Alternative accommodation contents Gold">
                  £25,000
                </ProductTableValue>,
              ],
            },

            {
              label: (
                <ProductTableLabel>
                  Credit cards in and away from home
                </ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Credit cards Bronze">
                  £1,000
                </ProductTableValue>,
                <ProductTableValue key="Credit cards Silver">
                  £1,000
                </ProductTableValue>,
                <ProductTableValue key="Credit cards Gold">
                  £1,000
                </ProductTableValue>,
              ],
            },
            {
              label: (
                <ProductTableLabel>
                  Personal money in the home
                </ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Personal money Bronze">
                  £250
                </ProductTableValue>,
                <ProductTableValue key="Personal money Silver">
                  £250
                </ProductTableValue>,
                <ProductTableValue key="Personal money Gold">
                  £1,000
                </ProductTableValue>,
              ],
            },
            {
              label: (
                <ProductTableLabel>Deep freezer contents</ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Deep freezer Bronze">
                  {config.SHOW_UPDATED_DOCS === 'true' ? '£400' : '£300'}
                </ProductTableValue>,
                <ProductTableValue key="Deep freezer Silver">
                  £500
                </ProductTableValue>,
                <ProductTableValue key="Deep freezer Gold">
                  £750
                </ProductTableValue>,
              ],
            },
            {
              label: (
                <ProductTableLabel>Property owners liability</ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Property owners liability Bronze">
                  £2,000,000
                </ProductTableValue>,
                <ProductTableValue key="Property owners liability Silver">
                  £2,000,000
                </ProductTableValue>,
                <ProductTableValue key="Property owners liability Gold">
                  £5,000,000
                </ProductTableValue>,
              ],
            },
            {
              label: (
                <ProductTableLabel>Replacement locks or keys</ProductTableLabel>
              ),
              values: [
                <ProductTableValue key="Replacement locks Bronze">
                  £300
                </ProductTableValue>,
                <ProductTableValue key="Replacement locks Silver">
                  £500
                </ProductTableValue>,
                <ProductTableValue key="Replacement locks Gold">
                  £1,000
                </ProductTableValue>,
              ],
            },
            {
              label: (
                <ProductTableLabel>Legal Expenses Select</ProductTableLabel>
              ),
              values: [
                <CrossIcon key="Legal Expenses Select Bronze" />,
                <TickIcon key="Legal Expenses Select Silver" />,
                <TickIcon key="Legal Expenses Select Gold" />,
              ],
            },
            {
              label: (
                <ProductTableLabel>Home Emergency Select</ProductTableLabel>
              ),
              values: [
                <CrossIcon key="Home Emergency Select Bronze" />,
                <TickIcon key="Home Emergency Select Silver" />,
                <TickIcon key="Home Emergency Select Gold" />,
              ],
            },
          ],
        },
      ],
    },
  };

  const iconsRowBannerData: IconsBannerData = {
    bgColor: colors.white,
    headerText: (
      <IconBannerHeader>
        The kind of home
        <br />
        insurance you want
      </IconBannerHeader>
    ),
    icons: [
      {
        icon: <OneExcessIcon />,
        text: (
          <IconBannerText>
            Making a claim under both Buildings & Contents?{' '}
            <SemiBoldSpan $isPurple>
              You'll only pay one policy excess.
            </SemiBoldSpan>
          </IconBannerText>
        ),
      },
      {
        icon: <OnlinePortalPurpleIcon />,
        text: (
          <IconBannerText>
            An online portal that {isDesktop && <br />}lets you
            <SemiBoldSpan $isPurple>
              {' '}
              manage your {isDesktop && <br />}policy 24/7.
            </SemiBoldSpan>
          </IconBannerText>
        ),
      },
      {
        icon: <TimerSpeedIcon />,
        text: (
          <IconBannerText>
            A Claims service that can 
            <SemiBoldSpan $isPurple>pay you direct</SemiBoldSpan> to cover the
            cost of replacing items.
          </IconBannerText>
        ),
      },
      {
        icon: <BoilerPurpleIcon />,
        text: (
          <IconBannerText>
            A range of{' '}
            <SemiBoldSpan $isPurple>
              flexible {isDesktop && <br />}add-ons available.{' '}
            </SemiBoldSpan>
            {isDesktop && <br />}{' '}
            <IconBannerMoreInfo
              onClick={() => {
                scrollTo('#cover-section');
              }}
            >
              (More info)
            </IconBannerMoreInfo>
          </IconBannerText>
        ),
      },
      {
        icon: <CompareChartIcon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>Three cover levels </SemiBoldSpan>
            {isDesktop && <br />}
            to suit your needs {isDesktop && <br />}and budget.
          </IconBannerText>
        ),
      },
      {
        icon: <Rated1Icon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>We’re rated No.1 </SemiBoldSpan>
            {isDesktop && <br />}
            for Home Insurance on {isDesktop && <br />}Reviewcentre.com**
          </IconBannerText>
        ),
      },
    ],
  };

  const heroBannerData = {
    title: (
      <ProductPageHeaderBannerTitle>
        Home insurance
      </ProductPageHeaderBannerTitle>
    ),
    text: (
      <HomeInsuranceHeaderText>
        Home customers{' '}
        <SemiBoldSpan $isPurple>saved an average of £87</SemiBoldSpan> when they
        joined us*
      </HomeInsuranceHeaderText>
    ),
  };

  const headerImage = (
    <HeroBannerImageContainer>
      <StaticImage
        src="../images/home_insurance_hero.png"
        alt="Home insurance for people like you"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    </HeroBannerImageContainer>
  );

  return (
    <OverflowContainer>
      <ImpactV2HeaderBanner
        headerImage={headerImage}
        backgroundColor={'bg-pxMint'}
        slantDirection={'bottom-left'}
        shapePosition={isMobile ? undefined : 'bottom-left-product-header'}
        heroBannerData={heroBannerData}
      />
      <ProductIconBanner data={iconsRowBannerData} hideShapeDesktop shape />

      <SlantedBackground
        backgroundColor={'bg-pxTan'}
        margin="mb-1-minus"
        slantDirection={'top-right'}
        height
      />
      <ProductBannerContainer>
        <Shape position="top-right-product-table" />

        <ProductBanner
          tableData={productBannerData.productTableData}
          policyDocuments={policyDocuments}
          backgroundColor={colors.tan1}
        />
      </ProductBannerContainer>
      <SlantedBackground
        id="cover-section"
        backgroundColor={'bg-pxPurple'}
        margin="mb-1-minus"
        slantDirection={'top-left'}
        height
      />
      <MultiTabTable data={multiTabTableData} />
      <SlantedBackground
        backgroundColor={'bg-pxMint'}
        margin="mb-1-minus"
        slantDirection={'top-right'}
        height
      />
      <ReviewSection
        cards={HomeInsuranceReviews}
        data={reviewsTitle}
        isHomePage
      />
    </OverflowContainer>
  );
};

export default HomeInsurancePage;

export const Head: HeadFC = () => (
  <>
    <title>Home insurance for people like you</title>
    <meta
      name="description"
      content="Award winning home insurance from Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
