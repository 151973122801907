import { styled } from 'styled-components';

export const RenderProductSectionContainer = styled.div`
  padding-bottom: 50px;

  @media (min-width: 1024px) {
    padding-bottom: 0;
  }
`;

export const RenderProductSectionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 55px;
`;

export const RenderProductSectionIcon = styled.img<{
  $isHome?: boolean;
  $isPet?: boolean;
}>`
  width: ${({ $isHome, $isPet }) =>
    $isHome ? '50px' : $isPet ? '96px' : '77px'};
  border-width: 0px;
  display: block;
  margin: 0 auto;
`;

export const RenderProductSectionText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 23.28px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 24px;
`;
