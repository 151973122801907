import React, { useState } from 'react';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';

import dayjs from 'dayjs';
import { HeadFC, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { v4 as uuidv4 } from 'uuid';

import carIcon from '../../../static/icons/car-icon.png';
import homeIcon from '../../../static/icons/home.svg';
import petIcon from '../../../static/icons/pet-icon.svg';
import ProductSection from '../../components/capture/RafProductSelection';
import Shape from '../../components/Shape';
import ShapeX from '../../components/ShapeX';
import Submission from '../../components/submission/Submission';
import useDisclaimer from '../../hooks/useDisclaimer';
import { SemiBoldSpan } from '../../styles/sharedStyled';
import {
  RafFooterLink,
  RafRenewalEmailConfirmationContainer,
  RafRenewalEmailConfirmationInput,
  RafRenewalPetNameInput,
} from './raf.style';
import {
  CarRegistrationInput,
  CarRegistrationInputContainer,
  CarRegistrationTextContainer,
  ProductSectionButton,
  ProductSectionContainer,
  RenewalCaptureContainer,
  RenewalCaptureContentWrapper,
  RenewalCaptureHeader,
  RenewalCaptureText,
  RenewalCaptureTextWrapper,
} from './renewals.style';

const rafDisclaimer = (
  <>
    <br />
    *Terms and conditions: By inputting your email and selecting your insurance
    renewal month/s in the landing page, you consent to Policy Expert contacting
    you to discuss your insurance prior to its renewal date. In order for your
    purchase to be considered eligible for your friend to receive a refer a
    friend reward, you must purchase your cover directly from us, either on
    policyexpert.co.uk, or over the phone. Your referring friend must be a live
    Policy Expert customer when your cover begins and you must not cancel your
    policy for 90 days from your start date. Your friend will only receive their
    voucher after this time has passed. You will not be considered a valid
    referral if you currently hold, or have previously held, a policy with
    Policy Expert. You can see the full terms and conditions{' '}
    <RafFooterLink
      target="_blank"
      href="/terms-and-conditions/?product=home#Refer%20a%20friend"
    >
      here
    </RafFooterLink>
    .
  </>
);

const Raf: React.FC<PageProps> = () => {
  useDisclaimer(rafDisclaimer);
  const { handleSubmit, register, control } = useFormContext<FormValues>();
  const [submitted, isSubmitted] = useState(false);

  const today = dayjs.tz(Date.now(), 'Europe/London').format('DD/MM/YYYY');
  const unixTimestamp = dayjs().unix();
  const { isDesktop } = useBreakpoint();

  type FormValues = {
    fhins_xsell_car_month: string;
    fhins_xsell_number_plate: string;
    fhins_xsell_home_month: string;
    fhins_xsell_pet_month: string;
    email: string;
    fhins_xsell_pet_name: string;
  };

  const watchEmailInput = useWatch({ name: 'email', control });

  const watchRenewalMonth = useWatch({
    name: [
      'fhins_xsell_home_month',
      'fhins_xsell_car_month',
      'fhins_xsell_pet_month',
    ],
    control,
  });

  const isValidEmail = watchEmailInput && watchEmailInput.match(/.+@.+\..+/);

  const isSubmitDisabled = !isValidEmail || !watchRenewalMonth;

  const submitDataToDataLayer: SubmitHandler<FormValues> = (data) => {
    const createEvent = (
      eventName: string,
      eventProperties: Record<string, string | number | undefined>
    ) => ({
      namespace: 'exponea',
      event_name: eventName,
      event_properties: {
        UID: uuidv4(),
        origin: 'RAF',
        status: 'CREATED',
        email: data.email?.toLowerCase().trim() || undefined,
        ...eventProperties,
      },
    });

    const homeDates =
      data.fhins_xsell_home_month &&
      createEvent('xsell_home_dates', {
        xsell_home_month: data.fhins_xsell_home_month,
        xsell_home_collected_date: today,
        xsell_home_collected_date_unix_timestamp: unixTimestamp,
      });

    const carDates =
      data.fhins_xsell_car_month &&
      createEvent('xsell_car_dates', {
        xsell_car_month: data.fhins_xsell_car_month,
        xsell_car_collected_date: today,
        xsell_car_collected_date_unix_timestamp: unixTimestamp,
        xsell_car_reg_plate:
          data.fhins_xsell_number_plate?.toUpperCase() || undefined,
      });

    const petDates =
      data.fhins_xsell_pet_month &&
      createEvent('xsell_pet_dates', {
        xsell_pet_month: data.fhins_xsell_pet_month,
        xsell_pet_collected_date: today,
        xsell_pet_collected_date_unix_timestamp: unixTimestamp,
        xsell_pet_name: data.fhins_xsell_pet_name,
      });

    const email = data.email?.toLowerCase().trim();
    if (email) {
      window.exponea?.identify({ email_id: email });

      const eventsToTrack = [
        { name: 'xsell_home_dates', data: homeDates },
        { name: 'xsell_car_dates', data: carDates },
        { name: 'xsell_pet_dates', data: petDates },
      ];

      eventsToTrack.forEach(({ name, data }) => {
        if (data && data.event_properties) {
          window.exponea?.track(name, data.event_properties);
        }
      });
    }

    isSubmitted(true);
  };

  return (
    <RenewalCaptureContainer>
      <RenewalCaptureContentWrapper>
        {submitted ? (
          <Submission isRafPage />
        ) : (
          <form onSubmit={handleSubmit(submitDataToDataLayer)}>
            <RenewalCaptureHeader>
              Not the right time to change insurer?
            </RenewalCaptureHeader>
            <RenewalCaptureTextWrapper>
              <RenewalCaptureText>
                No problem! Enter the month your home, car or pet insurance is
                due for renewal, and we'll send you a reminder at the right
                time.
              </RenewalCaptureText>
              <RenewalCaptureText>
                Plus, if you choose to insure with us later, your friend will
                still get a reward for introducing us.*
              </RenewalCaptureText>
            </RenewalCaptureTextWrapper>

            <ProductSectionContainer>
              <ProductSection
                id="home"
                iconSrc={homeIcon}
                iconAlt="Home icon"
                title="Home insurance"
                dropdownName="fhins_xsell_home_month"
                productType="home"
              />

              <ProductSection
                id="car"
                iconSrc={carIcon}
                iconAlt="Car icon"
                title="Car insurance"
                dropdownName="fhins_xsell_car_month"
                productType="car"
                extraContent={
                  <CarRegistrationInputContainer>
                    <CarRegistrationTextContainer>
                      UK
                    </CarRegistrationTextContainer>
                    <CarRegistrationInput
                      type="text"
                      {...register('fhins_xsell_number_plate', {
                        required: false,
                      })}
                      id="fhins_xsell_number_plate"
                      className="uppercase"
                      placeholder="ENTER REG"
                    />
                  </CarRegistrationInputContainer>
                }
              />

              <ProductSection
                id="pet"
                iconSrc={petIcon}
                iconAlt="Pet icon"
                title="Pet insurance"
                dropdownName="fhins_xsell_pet_month"
                productType="pet"
                extraContent={
                  <RafRenewalPetNameInput
                    type="text"
                    placeholder="Pet's name"
                    {...register('fhins_xsell_pet_name', { required: false })}
                  />
                }
              />
            </ProductSectionContainer>

            <RenewalCaptureTextWrapper $isRafPage>
              <RenewalCaptureText>
                <SemiBoldSpan $isPurple>
                  Confirm your contact email
                </SemiBoldSpan>{' '}
                below and then click 'Save my details'
              </RenewalCaptureText>
            </RenewalCaptureTextWrapper>

            <RafRenewalEmailConfirmationContainer>
              <RafRenewalEmailConfirmationInput
                type="email"
                placeholder="Your email address"
                {...register('email', { required: true })}
              />
            </RafRenewalEmailConfirmationContainer>

            <ProductSectionButton
              type="submit"
              className="renewal-capture-submit"
              disabled={isSubmitDisabled}
            >
              Save my details
            </ProductSectionButton>
          </form>
        )}
      </RenewalCaptureContentWrapper>

      {isDesktop && <Shape position="top-right-renewals" />}
      {isDesktop && <ShapeX position="bottom-left-renewals" />}
    </RenewalCaptureContainer>
  );
};

export default Raf;

export const Head: HeadFC = () => (
  <>
    <title>Refer a friend | Policy Expert</title>
    <meta name="description" content="Policy Expert Insurance Renewal" />
    <html lang="en" />
  </>
);
