import { styled } from 'styled-components';

import OnlinePortalSvg from '../../static/svgIcons/OnlinePortal.svg';
import PetCallCentre from '../../static/svgIcons/PetCallCentre.svg';
import PiggyBankSvg from '../../static/svgIcons/PiggyBank.svg';
import RatedNo1Svg from '../../static/svgIcons/RatedNo1.svg';
import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonSemiBold,
} from '../styles/sharedStyled';

export const IconBannerText = styled.p`
  font-size: 18px;
  margin-bottom: 0;
  letter-spacing: 0;
  line-height: normal;
`;

export const HeroBannerTitle = styled.h1`
  font-family: ${fontFamilyCamptonBook};
  font-size: 35px;
  line-height: normal;
  letter-spacing: 0;
  margin: 40px 0 15px;
  color: ${colors.white};

  @media screen and (min-width: 768px) {
    font-size: 40px;
    line-height: normal;
    margin: 0 0 25px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 50px;
    line-height: normal;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 65px;
    line-height: normal;
    letter-spacing: 1px;
  }
`;

export const HeroBannerText = styled.p`
  color: ${colors.white};
  font-size: 20px;
  line-height: normal;
  margin-bottom: 40px;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 50px;
    text-align: left;
  }

  @media screen and (min-width: 1024px) {
    font-size: 28px;
    line-height: normal;
    margin-bottom: 50px;
  }
`;

export const HeroBannerSpan = styled.span`
  font-family: ${fontFamilyCamptonSemiBold};
  color: ${colors.white};
`;

export const BackgroundContainer = styled.div<{
  $isHomepage?: boolean;
}>`
  background-color: ${({ $isHomepage }) =>
    $isHomepage ? `${colors.white}` : `${colors.gray3}`};
`;

export const MobileHeaderImageContainer = styled.div`
  z-index: 100;
  position: absolute;
  top: 840px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 423px) {
    top: 800px;
  }

  width: 330px;
  height: 370px;

  @media screen and (min-width: 390px) {
    width: 360px;
    height: 392px;
  }
`;

export const PiggyBankIcon = styled(PiggyBankSvg)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const OnlinePortalIcon = styled(OnlinePortalSvg)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const RatedNo1Icon = styled(RatedNo1Svg)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const CallCentreIcon = styled(PetCallCentre)`
  height: 80px;

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const ShapeContainer = styled.div`
  position: relative;
  z-index: 100;
`;
