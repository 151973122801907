import React from 'react';

import { HeadFC, PageProps } from 'gatsby';

import PolicyBooks, {
  PolicyDocumentsData,
} from '../../components/policyBooks/PolicyBooks';
import config from '../../config';

const HomePolicyDocumentsPage: React.FC<PageProps> = () => {
  const documentsData: PolicyDocumentsData = {
    productName: 'Home',
    productTypeNames: [
      'Home insurance',
      'Legal expenses',
      'Home emergency',
      'Key cover',
    ],
    productTypeData: {
      'Home insurance': [
        {
          policyType: 'bronze',
          ...(config.SHOW_UPDATED_DOCS === 'true'
            ? {
                policyWordingLink: `${config.DOCUMENTS_URL}/home/qgl14/home_pebronzehome_wording_1124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home/qgl14/home_pebronzehome_ipid_1124.pdf`,
              }
            : {
                policyWordingLink: `${config.DOCUMENTS_URL}/home/qgl14/home_pebronzehome_wording_0124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home/qgl14/home_pebronzehome_ipid_0623.pdf`,
              }),
        },
        {
          policyType: 'silver',
          ...(config.SHOW_UPDATED_DOCS === 'true'
            ? {
                policyWordingLink: `${config.DOCUMENTS_URL}/home/qgl10/home_pesilverhome_wording_1124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home/qgl10/home_pesilverhome_ipid_1124.pdf`,
              }
            : {
                policyWordingLink: `${config.DOCUMENTS_URL}/home/qgl10/home_pesilverhome_wording_0124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home/qgl10/home_pesilverhome_ipid_0623.pdf`,
              }),
        },
        {
          policyType: 'gold',
          ...(config.SHOW_UPDATED_DOCS === 'true'
            ? {
                policyWordingLink: `${config.DOCUMENTS_URL}/home/qgl11/home_pegoldhome_wording_1124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home/qgl11/home_pegoldhome_ipid_1124.pdf`,
              }
            : {
                policyWordingLink: `${config.DOCUMENTS_URL}/home/qgl11/home_pegoldhome_wording_0124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home/qgl11/home_pegoldhome_ipid_0623.pdf`,
              }),
        },

        {
          policyType: 'protect',
          policyWordingLink: `${config.DOCUMENTS_URL}/home/qgl15/home_peprotecthome_wording_1023.pdf`,
          ipidLink: `${config.DOCUMENTS_URL}/home/qgl15/home_peprotecthome_ipid_1023.pdf`,
        },
      ],
      'Legal expenses': [
        {
          policyType: 'select',
          ...(config.SHOW_UPDATED_DOCS === 'true'
            ? {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-select-free/homelegalselect_wording_1124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-select-free/homelegalselect_ipid_1124.pdf`,
              }
            : {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-select-free/homelegalselect_wording_0623.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-select-free/homelegalselect_ipid_0623.pdf`,
              }),
        },
        {
          policyType: 'plus',
          ...(config.SHOW_UPDATED_DOCS === 'true'
            ? {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-plus/homelegalplus_wording_1124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-plus/homelegalplus_ipid_1124.pdf`,
              }
            : {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-plus/homelegalplus_wording_0623.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-legal/home-legal-plus/homelegalplus_ipid_0623.pdf`,
              }),
        },
      ],
      'Home emergency': [
        {
          policyType: 'select',
          ...(config.SHOW_UPDATED_DOCS === 'true'
            ? {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-select/homeemergencyselect_wording_1124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-select/homeemergencyselect_ipid_1124.pdf`,
              }
            : {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-select/homeemergencyselect_wording_0424.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-select/homeemergencyselect_ipid_0424.pdf`,
              }),
        },
        {
          policyType: 'plus',
          ...(config.SHOW_UPDATED_DOCS === 'true'
            ? {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-plus/homeemergencyplus_wording_1124.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-plus/homeemergencyplus_ipid_1124.pdf`,
              }
            : {
                policyWordingLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-plus/homeemergencyplus_wording_0424.pdf`,
                ipidLink: `${config.DOCUMENTS_URL}/home-emergency/home-emergency-plus/homeemergencyplus_ipid_0424.pdf`,
              }),
        },
      ],
      'Key cover': [
        {
          policyType: 'key',
          policyWordingLink: `${config.DOCUMENTS_URL}/key/key-cover-onefob/homekeycare_wording_0624.pdf`,
          ipidLink: `${config.DOCUMENTS_URL}/key/key-cover-onefob/homekeycare_ipid_0624.pdf`,
        },
      ],
    },
  };

  return <PolicyBooks documentsData={documentsData} />;
};

export default HomePolicyDocumentsPage;

export const Head: HeadFC = () => (
  <>
    <title>Home insurance policy documents | Policy Expert</title>
    <meta
      name="description"
      content="Home insurance policy documents. Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
