import { styled } from 'styled-components';

import { fontFamilyCamptonMedium } from '../../styles/sharedStyled';

export const IconBannerBackgroundContainer = styled.div<{
  $backgroundColor: string;
  $isHomepage?: boolean;
}>`
  width: 100vw;
  position: relative;
  z-index: 20;
  text-align: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor || ''};
  overflow: hidden;
  margin-bottom: -34px;
  padding: 60px 24px 95px;

  @media (min-width: 768px) {
    margin-bottom: -79px;
    padding: 0 40px;
  }

  @media (min-width: 1024px) {
    padding: 130px 0 210px;
  }

  ${({ $isHomepage }) =>
    $isHomepage
      ? 'padding-top: 100px; @media (min-width: 768px) {padding-top: 80px; padding-bottom: 180px;} @media (min-width: 1024px) {padding-top: 120px; padding-bottom: 210px;}'
      : '@media (min-width: 768px) {padding-top: 100px; padding-bottom: 210px;} @media (min-width: 1024px) {padding-top: 130px;}'}
`;

export const IconBannerInformationWrapper = styled.div`
  z-index: 20;
  max-width: 1390px;
  margin: 0 auto;
`;

export const IconBannerHeader = styled.h2<{
  $isHomepage?: boolean;
}>`
  font-size: 35px;
  font-family: ${fontFamilyCamptonMedium};

  @media (min-width: 768px) {
    font-size: ${({ $isHomepage }) => ($isHomepage ? '40px' : '50px')};
  }

  @media (min-width: 1024px) {
    font-size: ${({ $isHomepage }) => ($isHomepage ? '65px' : '80px')};
  }
`;

export const IconBannerGrid = styled.div<{
  $isHomepage?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: ${({ $isHomepage }) => ($isHomepage ? '30px' : '50px')};
  padding: 50px 0 0;

  @media (min-width: 768px) {
    grid-template-columns: ${({ $isHomepage }) =>
      $isHomepage ? 'repeat(2, minmax(0, 1fr))' : 'repeat(3, minmax(0, 1fr))'};
    row-gap: ${({ $isHomepage }) => $isHomepage && '40px'};
    align-items: flex-start;
    padding-top: ${({ $isHomepage }) => ($isHomepage ? '60px' : '70px')};
  }

  @media (min-width: 1024px) {
    grid-template-columns: ${({ $isHomepage }) =>
      $isHomepage && 'repeat(4, minmax(0, 1fr))'};

    gap: ${({ $isHomepage }) => ($isHomepage ? '40px' : '60px')};
    padding: 90px 40px 0;
  }
`;

export const IconBannerInformationContainer = styled.div<{
  $isHomepage?: boolean;
}>`
  display: flex;
  flex-direction: ${({ $isHomepage }) => ($isHomepage ? 'row' : 'column')};
  align-items: center;
  margin: 0 auto;
  max-width: ${({ $isHomepage }) => ($isHomepage ? '600px' : '320px')};
  column-gap: ${({ $isHomepage }) => $isHomepage && '20px'};

  @media (min-width: 768px) {
    flex-direction: ${({ $isHomepage }) => $isHomepage && 'column'};
    gap: ${({ $isHomepage }) => $isHomepage && '20px'};
    max-width: ${({ $isHomepage }) => ($isHomepage ? '240px' : '320px')};
  }
`;

export const IconBannerTextContainer = styled.div<{
  $isHomepage?: boolean;
}>`
  margin-top: ${({ $isHomepage }) => !$isHomepage && '30px'};
  text-align: ${({ $isHomepage }) => $isHomepage && 'left'};

  @media (min-width: 768px) {
    text-align: ${({ $isHomepage }) => $isHomepage && 'center'};
  }
`;
