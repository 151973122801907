import React from 'react';

import {
  ReturnToHomeLink,
  SubmittedContainer,
  SubmittedHeader,
  SubmittedText,
} from './Submission.style';

type SubmissionProps = {
  isRafPage?: boolean;
};

const Submission = ({ isRafPage }: SubmissionProps) => (
  <SubmittedContainer data-testid="submitted-container">
    <SubmittedHeader>You're all set!</SubmittedHeader>
    <SubmittedText>Your details have been saved</SubmittedText>
    {isRafPage && (
      <ReturnToHomeLink to="/">Return to homepage</ReturnToHomeLink>
    )}
  </SubmittedContainer>
);

export default Submission;
