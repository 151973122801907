import { styled } from 'styled-components';

import {
  colors,
  fontFamilyCamptonBook,
  fontFamilyCamptonRegular,
} from '../../styles/sharedStyled';

export const RafRenewalEmailConfirmationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RafRenewalEmailConfirmationInput = styled.input<{
  $hasError?: boolean;
}>`
  width: 240px;
  height: 50px;
  font-family: ${fontFamilyCamptonBook};
  background-color: ${colors.white};
  border-radius: 10px;
  border: 1px solid ${colors.gray15};
  padding: 15px 20px;
  line-height: normal;

  @media (min-width: 768px) {
    width: 450px;
    height: 50px;
    font-size: 16px;
  }
`;

export const RafRenewalPetNameInput = styled.input`
  position: relative;
  font-family: ${fontFamilyCamptonRegular};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.purple7};
  background-color: ${colors.gray2};
  appearance: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  border-radius: 5px;
  width: 240px;
  height: 50px;
  box-sizing: border-box;
  padding: 0px 8px;
  cursor: text;
  color: ${colors.black};
  margin-top: 10px;
`;

export const RafFooterLink = styled.a`
  color: ${colors.black};
  cursor: pointer;
  text-decoration: underline;
`;
