import React from 'react';

import { HeadFC, Link, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import HeaderBanner from '../components/headerBanner/HeaderBanner';
import NoCarouselTable from '../components/noCarouselTable/NoCarouselTable';
import { ProductTableDataType } from '../components/productTable/ProductTable';
import TwoColumnHTMLBanner from '../components/twoColumnHTMLBanner/TwoColumnHTMLBanner';
import useDisclaimer from '../hooks/useDisclaimer';
import HeaderImageBreakpoints from '../types/breakpoints';
import TermsCopyType from '../types/terms';

const getHeaderImage = ({ isMobile, isTablet }: HeaderImageBreakpoints) => {
  if (isMobile) {
    return (
      <StaticImage
        src="../images/trusted_partners_sm.png"
        alt="Trusted partners"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  if (isTablet) {
    return (
      <StaticImage
        src="../images/trusted_partners_md.png"
        alt="Trusted partners"
        className="h-full"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    );
  }
  return (
    <StaticImage
      src="../images/trusted_partners_lg.png"
      alt="Trusted partners"
      className="h-full"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const partnersTableData: ProductTableDataType = {
  bgColor: 'bg-pxCharcoal',
  textColor: 'text-pxWhite',
  headerText: 'Partners listed as of May 2024.',
  rightPanelInfo: [
    {
      infoTitle: '',
      info: 'We have several trusted partners who help us in the quote, sale, and delivery of our products to our customers. Information on these partners, the product/service they offer, and their privacy notices are summarised below.',
    },
  ],
  columns: 2,
  tableTitles: [
    {
      tableTitle: 'Partners',
    },
    {
      tableTitle: 'Product(s)',
    },
  ],
  productRows: [
    {
      label: 'Accredited Insurance (Europe) Limited - UK Branch',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Home insurance, Car insurance, Home Emergency, Legal expenses
          insurance, Replacement vehicle insurance, Personal accident insurance
          and Excess protection.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.rqaccredited.com/privacy-notice-uk-eu/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Ageas Insurance Limited',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Car insurance, Van insurance.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.ageas.co.uk/privacy-policies/customers-privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'AXA Insurance UK plc',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Car insurance, Van insurance.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.axa.co.uk/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Call Assist (underwritten by DAS Insurance)',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Motor breakdown insurance.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.call-assist.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Covea Insurance plc',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Car insurance, Van insurance.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.coveainsurance.co.uk/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Flood Re',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Flood re-insurance for certain home insurance policies.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.floodre.co.uk/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Keycare Limited',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Key cover.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.keycare.co.uk/home/PrivacyPolicy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'QIC Europe Limited',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Home insurance, Car insurance, Home Emergency, Legal expenses
          insurance, Replacement vehicle insurance, Personal accident insurance
          and Excess protection.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://qiceuropeltd.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
  ],
};

const paymentPartners: ProductTableDataType = {
  bgColor: 'bg-pxTan',
  textColor: 'text-pxBlack',
  headerText: 'Payment Processing Partners',
  rightPanelInfo: [
    {
      infoTitle: '',
      info: 'When a customer pays for a policy via Policy Expert their payment information is processed by an external company, regulated by the Financial Conduct Authority. We work with partners, who process card payments, bank payments and provide premium financing.',
    },
  ],
  columns: 2,
  tableTitles: [
    {
      tableTitle: 'Partner',
    },
    {
      tableTitle: 'Service',
    },
  ],
  productRows: [
    {
      label: 'Close Brothers Premium Finance (CPF)',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Process and manage customer premium finance and customer bank account
          details. If a customer is accepted for premium finance, a hard
          footprint may appear on their credit file.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.closebrotherspf.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'GoCardless',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Process customer payments from customer bank account and store bank
          account details.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://gocardless.com/privacy/payers/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Paysafe',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Process customer credit and debit card payments and refunds and store
          card details. Also, where possible and if requested by Policy Expert,
          update customer card details.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.paysafe.com/gb-en/paysafegroup/comprehensive-privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Premium Credit (PCL)',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Process and manage customer premium finance and customer bank account
          details. If a customer is accepted for premium finance, a hard
          footprint may appear on their credit file.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.premiumcredit.com/privacy-notice"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Worldpay',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Process customer credit and debit card payments and refunds and store
          card details. Also, where possible and if requested by Policy Expert,
          update customer card details.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.worldpay.com/en-gb/privacy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
  ],
};

const claimsTableData: ProductTableDataType = {
  bgColor: 'bg-pxBlack',
  textColor: 'text-pxWhite',
  headerText: 'Claims Information and Credit Reference Partners',
  rightPanelInfo: [
    {
      infoTitle: '',
      info: 'When a customer requests an insurance quote their details are checked with a credit reference agency and the Claims and Underwriting Exchange service for identity verification and fraud prevention. The partners we work with are listed below.',
    },
  ],
  columns: 2,
  tableTitles: [
    {
      tableTitle: 'Partners',
    },
    {
      tableTitle: 'Service',
    },
  ],
  productRows: [
    {
      label: 'Claims and Underwriting Exchange (CUE)',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          CUE holds a record of incidents reported to insurance companies by
          policyholders and third-party claimants and provides validation of the
          information provided by the customer, both about the customer and
          about any other person named on the application, against the
          information held on CUE and against other databases. The MIB are the
          data controller for all CUE databases. Insurers may use this
          information to consider whether to accept the risk.
          <br />
          <br />
          <span className="text-pxCharcoal">
            For details relating to information held about customers on the
            Claims and Underwriting Exchange please visit:
          </span>{' '}
          <a
            href="https://www.mib.org.uk/privacy-and-cookie-policy/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Experian Limited',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Credit reference agency used for the purpose of identity verification
          and fraud prevention.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.experian.co.uk/consumer/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Insurance Fraud Bureau',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Access and sharing of data for fraud prevention / detection purposes.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.insurancefraudbureau.org/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'LexisNexis',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Act as an intermediary between Policy Expert and both our credit
          reference agencies (Experian and TransUnion) and the Claims and
          Underwriting Exchange (CUE), passing the customers data to both
          services, and then passing the response back to Policy Expert. In
          addition we use the following LexisNexis services: Policy Insights,
          Risk Insights, Attract, No Claims Discount Module and Quote
          Intelligence.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.lexisnexis.com/global/privacy/en/privacy-policy-uk.page"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'MiExact Limited',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Access to HALO mortality data for fraud prevention / detection
          purposes.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://miexact.com/privacy-policy-halo-mortascreen"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Sedgwick International UK',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Process and manage customer claims and claims related complaints.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.sedgwick.com/en-gb/privacy/?loc=uk"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Synectics Solutions Limited',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Access and sharing of data for fraud prevention / detection purposes.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.synectics-solutions.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'TransUnion Information Group',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Credit reference agency used for the purpose of identity verification
          and fraud prevention, their search may appear on a customer’s credit
          report whether or not the quote proceeds, but will not impact the
          customer’s credit history.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.transunion.co.uk/legal-information/bureau-privacy-notice"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
    {
      label: 'Trinity M Limited',
      included: [],
      includedHTML: (
        <p className="text-left text-finerDetailsBodyCopy text-pxPurple">
          Process and manage customer claims and claims related complaints.
          <br />
          <br />
          <span className="text-pxCharcoal">
            You can view their privacy policy
          </span>{' '}
          <a
            href="https://www.trinityclaims.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      ),
    },
  ],
};

const additionalPartners: TermsCopyType = {
  bgColor: 'bg-pxMint',
  heading: 'Additional partners',
  body: (
    <div>
      <p className="small-text">
        We also use trusted suppliers who provide us with necessary services for
        supporting our customers, for example print and mailing companies, IT
        support services such as cloud servers, and claims administration
        services. If you would like more details of these suppliers please get
        in touch via our contact page.
      </p>
      <Link className="text-pxPink large font-semiBold" to="/contact">
        Contact us
      </Link>
    </div>
  ),
};

const TrustedPartnersPage: React.FC<PageProps> = () => {
  useDisclaimer(null);

  const { isMobile, isTablet } = useBreakpoint();
  const headerImage = getHeaderImage({ isMobile, isTablet });

  return (
    <>
      <HeaderBanner
        backgroundColor="bg-pxMint"
        headerImage={headerImage}
        shapePosition="bottom-left-header-low"
        maxHeight="max-h-600"
        styling="pt-9 pl-26px md:pt-40px md:px-90px lg:pt-54px lg:pl-46px lg:pr-12 max-w-1089 relative z-1"
      />
      <NoCarouselTable data={partnersTableData} />
      <NoCarouselTable data={paymentPartners} />
      <NoCarouselTable data={claimsTableData} />
      <TwoColumnHTMLBanner data={additionalPartners} />
    </>
  );
};

export default TrustedPartnersPage;

export const Head: HeadFC = () => (
  <>
    <title>Trusted partners | Policy Expert</title>
    <meta name="description" content="" />
    <html lang="en" />
  </>
);
