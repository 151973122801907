import React from 'react';

import { HeadFC } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import ContactBanner, {
  ContactBannerData,
} from '../components/contactBanner/ContactBanner';
import { ContactBannerCardImageWrapper } from '../components/contactBanner/ContactBanner.style';
import CtaBannerImpactV2 from '../components/ctaBanner/CtaBannerImpactV2';
import ImpactV2HeaderBanner from '../components/headerBanner/HeaderBannerV2';
import IconsBanner, {
  IconsBannerData,
} from '../components/iconsBanner/IconsBanner';
import ReviewSection from '../components/reviewBanner/ReviewSectionNoImage';
import ReviewCard from '../components/reviewCard/ReviewCard';
import Shape from '../components/Shape';
import SlantedBackground from '../components/slantedBackground/SlantedBackground';
import config from '../config';
import useDisclaimer from '../hooks/useDisclaimer';
import {
  colors,
  OverflowContainer,
  SemiBoldSpan,
} from '../styles/sharedStyled';
import { ReviewData } from '../types/reviews';
import {
  BackgroundContainer,
  CallCentreIcon,
  HeroBannerSpan,
  HeroBannerText,
  HeroBannerTitle,
  IconBannerText,
  MobileHeaderImageContainer,
  OnlinePortalIcon,
  PiggyBankIcon,
  RatedNo1Icon,
  ShapeContainer,
} from './index.style';

const iconsRowBannerData: IconsBannerData = {
  bgColor: colors.white,
  headerText: <>Here's why people choose us...</>,
  icons: [
    {
      icon: <PiggyBankIcon />,
      text: (
        <IconBannerText>
          Customers{' '}
          <SemiBoldSpan $isPurple>saved an average of £99</SemiBoldSpan> when
          they switched to us**
        </IconBannerText>
      ),
    },
    {
      icon: <OnlinePortalIcon />,
      text: (
        <IconBannerText>
          Manage your policy online with{' '}
          <SemiBoldSpan $isPurple>
            instant access to your documents
          </SemiBoldSpan>
          , 24 hours a day.
        </IconBannerText>
      ),
    },
    {
      icon: <RatedNo1Icon />,
      text: (
        <IconBannerText>
          <SemiBoldSpan $isPurple>
            Over 96% of customers would recommend us
          </SemiBoldSpan>{' '}
          to friends and family***
        </IconBannerText>
      ),
    },
    {
      icon: <CallCentreIcon />,
      text: (
        <IconBannerText>
          Speak to real people about your policy in our{' '}
          <SemiBoldSpan $isPurple>UK-based call centre.</SemiBoldSpan>
        </IconBannerText>
      ),
    },
  ],
};

const reviewsTitle = { headerText: 'People love what we do...' };

const getHeaderImage = () => {
  return (
    <StaticImage
      src="../images/updated_homepage_hero.png"
      alt="Insurance for people like you"
      className="md:h-325px md:w-290px md:top-10px lg:h-480px lg:w-430px xl:h-540px xl:w-481px lg:top-0"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />
  );
};

const homepageDisclaimer = (
  <>
    <br />
    *The number of customers insured by Policy Expert - July 2024.
    <br />
    **The number of customers who reported savings when they switched their
    insurance to Policy Expert, based on 646 survey responses carried out by
    QMetric Group Limited in May 2024.
    <br />
    ***Policy Expert is rated the number 1 home insurance provider in the UK on
    independent review website – Reviewcentre.com. Based on 58,736 customer
    reviews and a 96.6% recommendation rating, June 2024.
  </>
);

const heroBannerData = {
  title: (
    <HeroBannerTitle>
      Insurance trusted by over <HeroBannerSpan>1.5 million</HeroBannerSpan>{' '}
      people*
    </HeroBannerTitle>
  ),

  text: (
    <HeroBannerText>
      Join us today. <HeroBannerSpan>Get a quote</HeroBannerSpan>
    </HeroBannerText>
  ),
};

const HomepageReviewsData: ReviewData[] = [
  {
    title: 'Jolly good price',
    author: 'Joan',
    date: '09/04/2024',
    review:
      'Just spoke and answered all my questions in language I could understand, no rush and at the end a very good price which I was over the moon with.',
  },
  {
    title: 'Brilliant customer service',
    author: 'Mr P Windows',
    date: ' 20/03/2024',
    review:
      'Good value policy with great customer service. Unfortunately had a claim last year when a lorry damaged our property, but Policy Expert quickly sorted it out.',
  },
  {
    title: 'Easy and straight forward',
    author: 'Trevor Von Trilsbach',
    date: '03/04/2024',
    review:
      'Easy and straight forward to take up my Motor Insurance quote which was very competitively priced at £331.08. Would highly recommend Policy Expert for Motor and Home Insurance as I now have both policies with them.',
  },
  {
    title: 'Efficient claims process',
    author: 'Eileen B',
    date: '03/04/2024',
    review:
      'I made one claim for damage to a shower room a few years back which was dealt with efficiently. This year I auto renewed as I could not find a more competitive deal elsewhere. When I have contacted the staff they have always been very knowledgeable, helpful and polite.',
  },
];

const HomepageReviews: JSX.Element[] = [];
HomepageReviewsData.map((item) => {
  HomepageReviews.push(
    <ReviewCard key={`carousel-${item.review}`} data={item} />
  );
});

const contactBannerData: ContactBannerData = {
  bgColor: colors.charcoal,
  headerText: <>Need some help?</>,
  cards: [
    {
      cardBgColor: colors.pink11,
      cardHeaderText: 'Manage your policy',
      cardSubText: (
        <>
          Log in to your customer portal to{' '}
          <SemiBoldSpan $isPurple>
            view your policy documents, renew your cover
          </SemiBoldSpan>{' '}
          and more.
        </>
      ),
      buttons: [
        {
          label: 'Log in',
          url: '/login',
        },
      ],
      image: (
        <ContactBannerCardImageWrapper>
          <StaticImage
            src="../images/manage_policy.png"
            alt="Insurance for people like you"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        </ContactBannerCardImageWrapper>
      ),
    },
    {
      cardBgColor: colors.purple15,
      isReverse: true,
      cardHeaderText: 'Got a question?',
      cardSubText: (
        <>
          <SemiBoldSpan $isPurple>
            We've got a brilliant library of FAQs
          </SemiBoldSpan>{' '}
          - have a look to see if we've already answered your question!
        </>
      ),
      buttons: [
        {
          label: 'Visit the Help Centre',
          url: config.HELP_CENTRE_URL,
        },
      ],
      image: (
        <ContactBannerCardImageWrapper>
          <StaticImage
            src="../images/help_centre.png"
            alt="Insurance for people like you"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
        </ContactBannerCardImageWrapper>
      ),
    },
  ],
};

const IndexPage: React.FC = () => {
  const { isMobile, isTablet } = useBreakpoint();

  useDisclaimer(homepageDisclaimer);

  const headerImage = getHeaderImage();

  return (
    <OverflowContainer>
      <BackgroundContainer $isHomepage>
        <ImpactV2HeaderBanner
          headerImage={headerImage}
          backgroundColor="bg-pxPurple"
          slantDirection="bottom-left"
          heroBannerData={heroBannerData}
          isHomepage
        />
        {((!isTablet && !isMobile) || (isTablet && !isMobile)) && (
          <CtaBannerImpactV2 isHomepage />
        )}
        {isMobile && (
          <MobileHeaderImageContainer>{headerImage}</MobileHeaderImageContainer>
        )}
      </BackgroundContainer>

      <IconsBanner data={iconsRowBannerData} shape isHomepage />

      <ShapeContainer>
        <SlantedBackground
          backgroundColor={'bg-pxCharcoal'}
          margin="mb-1-minus"
          slantDirection={'top-right'}
          height
        />
        <ContactBanner data={contactBannerData} />
        <Shape position="top-left-homepage-contact" />
        <SlantedBackground
          backgroundColor={'bg-pxMint'}
          margin="mb-1-minus"
          slantDirection={'top-left'}
          height
        />

        <ReviewSection cards={HomepageReviews} data={reviewsTitle} isHomePage />
      </ShapeContainer>
    </OverflowContainer>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Policy Expert: Home and Car insurance for people like you</title>
    <meta
      name="description"
      content="Low price insurance, easy to understand policies and stress free claims. That’s why 86% of home customers stay with us every year. Insurance for people like you."
    />
    <html lang="en" />
  </>
);
