import { Link } from 'gatsby';
import { styled } from 'styled-components';

import ChartSvg from '../../../static/svgIcons/ChartWhiteBg.svg';
import { colors, fontFamilyCamptonSemiBold } from '../../styles/sharedStyled';

export const PolicyDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 50px 24px 0;

  @media (min-width: 768px) {
    margin-top: 60px;
  }

  @media (min-width: 1024px) {
    margin-top: 90px;
  }
`;

export const PolicyDocumentsHeaderTitle = styled.h4`
  letter-spacing: 0;
  line-height: normal;
  font-size: 22px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 40px;
  }
`;

export const PolicyDocumentsHeaderText = styled.p`
  font-size: 18px;
  line-height: normal;
  margin-bottom: 40px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    max-width: 500px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    margin-bottom: 50px;
    max-width: 650px;
  }
`;

export const PolicyDocumentsButton = styled(Link)`
  width: 100%;
  max-width: 342px;
  margin: 0 auto;
  font-family: ${fontFamilyCamptonSemiBold};
  font-weight: 600;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 25px 32px;
  font-size: 15px;
  height: 41px;
  border-color: ${colors.pink2};
  border-width: 2px;
  border-radius: 15px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  white-space: nowrap;
  vertical-align: middle;
  background-color: ${colors.white};
  display: flex;
  color: ${colors.pink2};

  &:hover {
    background-color: ${colors.pink2};
    color: ${colors.white};
  }

  @media (min-width: 768px) {
    height: 68px;
    max-width: 300px;
    font-size: 20px;
    padding: 0 82px;
  }
`;

export const ChartIcon = styled(ChartSvg)`
  width: 32px;
  height: 43px;
  margin-bottom: 20px;
`;
