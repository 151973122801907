import React, { ReactNode } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { IconTextType } from '../../types/icon';
import Icon from '../icons/Icon';
import ShapeX from '../ShapeX';
import {
  IconBannerBackgroundContainer,
  IconBannerGrid,
  IconBannerHeader,
  IconBannerInformationContainer,
  IconBannerInformationWrapper,
  IconBannerTextContainer,
} from './IconsBanner.style';

export type IconsBannerData = {
  bgColor: string;
  headerText: ReactNode;
  icons: IconTextType[];
};

type Props = {
  data: IconsBannerData;
  shape?: boolean;
  isHomepage?: boolean;
};

const IconsBanner = ({ data, shape, isHomepage }: Props) => {
  const { isMobile, isTablet, isTabletLarge, isDesktop } = useBreakpoint();

  const hideShape = shape ? isTabletLarge || isTablet || isMobile : '';

  return (
    <IconBannerBackgroundContainer
      $backgroundColor={data.bgColor}
      $isHomepage={isHomepage}
    >
      <IconBannerInformationWrapper>
        <IconBannerHeader $isHomepage={isHomepage}>
          {data.headerText}
        </IconBannerHeader>
        <IconBannerGrid $isHomepage={isHomepage}>
          {data.icons.map((item) => (
            <IconBannerInformationContainer
              key={item.text?.toString()}
              data-testid="icon-banner"
              $isHomepage={isHomepage}
            >
              <Icon>{item.icon}</Icon>
              <IconBannerTextContainer $isHomepage={isHomepage}>
                {item.text}
              </IconBannerTextContainer>
            </IconBannerInformationContainer>
          ))}
        </IconBannerGrid>
      </IconBannerInformationWrapper>
      {isDesktop && isHomepage ? (
        <ShapeX position={'bottom-right-icon-banner'} />
      ) : (
        hideShape && isHomepage && ''
      )}
    </IconBannerBackgroundContainer>
  );
};

export default IconsBanner;
