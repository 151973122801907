import React from 'react';

import {
  ChartIcon,
  PolicyDocumentsButton,
  PolicyDocumentsContainer,
  PolicyDocumentsHeaderText,
  PolicyDocumentsHeaderTitle,
} from './ProductBannerPolicyDocuments.style';

export type ProductBannerPolicyDocumentsDataType = {
  headerTitle: string;
  headerText: string;
  button: {
    label: string;
    url: string;
  };
};

const ProductBannerPolicyDocuments = ({
  headerTitle,
  headerText,
  button,
}: ProductBannerPolicyDocumentsDataType) => (
  <PolicyDocumentsContainer>
    <ChartIcon />
    <PolicyDocumentsHeaderTitle>{headerTitle}</PolicyDocumentsHeaderTitle>
    <PolicyDocumentsHeaderText>{headerText}</PolicyDocumentsHeaderText>
    <PolicyDocumentsButton key={button.label} to={button.url}>
      {button.label}
    </PolicyDocumentsButton>
  </PolicyDocumentsContainer>
);

export default ProductBannerPolicyDocuments;
