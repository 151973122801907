import React from 'react';

import { HeadFC } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';
import scrollTo from 'gatsby-plugin-smoothscroll';

import ImpactV2HeaderBanner from '../components/headerBanner/HeaderBannerV2';
import {
  CrossIcon,
  TickIcon,
} from '../components/homeEmergencyTable/HomeEmergencyTable.style';
import { IconsBannerData } from '../components/iconsBanner/IconsBanner';
import MultiTabTable, {
  MultiTabTableData,
} from '../components/multiTabTable/MultiTabTable';
import ProductIconBanner from '../components/petIconsBanner/PetIconsBanner';
import {
  IconBannerHeader,
  IconBannerMoreInfo,
  IconBannerSmallText,
  IconBannerText,
} from '../components/petIconsBanner/PetIconsBanner.style';
import {
  ProductTableLabel,
  ProductTableLabelSubText,
  ProductTableValue,
} from '../components/petProductTable/PetProductTable.style';
import ProductBanner from '../components/productBanner/ProductBanner';
import { ProductBannerPolicyDocumentsDataType } from '../components/productBannerPolicyDocuments/ProductBannerPolicyDocuments';
import ReviewSection from '../components/reviewBanner/ReviewSectionNoImage';
import ReviewCard from '../components/reviewCard/ReviewCard';
import Shape from '../components/Shape';
import SlantedBackground from '../components/slantedBackground/SlantedBackground';
import useDisclaimer from '../hooks/useDisclaimer';
import {
  CarSvg,
  ClockSvg,
  CoinsSvg,
  colors,
  CourtSvg,
  HeroBannerImageContainer,
  KeySvg,
  OverflowContainer,
  PhoneSvg,
  PolicyExpertBronzeIcon,
  PolicyExpertGoldIcon,
  PolicyExpertSilverIcon,
  ProductBannerContainer,
  ProductPageHeaderBannerTitle,
  ScaleSvg,
  SemiBoldSpan,
  TriangleSvg,
} from '../styles/sharedStyled';
import { ReviewData } from '../types/reviews';
import {
  AssistanceIcon,
  CarInsuranceHeaderText,
  CoinsIcon,
  LockSvg,
  NurseSvg,
  RepairWarrantyIcon,
  TickSvg,
  TowTruckIcon,
  UkSvg,
} from './car-insurance.style';
import {
  CompareChartIcon,
  OnlinePortalPurpleIcon,
} from './home-insurance.style';

const productBannerData = {
  productTableData: {
    tableHeaderIcons: [
      <PolicyExpertBronzeIcon key="PolicyExpertBronzeIcon" />,
      <PolicyExpertSilverIcon key="PolicyExpertSilverIcon" />,
      <PolicyExpertGoldIcon key="PolicyExpertGoldIcon" />,
    ],
    tableContent: [
      {
        productRows: [
          {
            label: (
              <ProductTableLabel>
                Courtesy Car{' '}
                <ProductTableLabelSubText>
                  (Subject to availability)
                </ProductTableLabelSubText>
              </ProductTableLabel>
            ),
            values: [
              <TickIcon key="Courtesy car Bronze" />,
              <TickIcon key="Courtesy car Silver" />,
              <TickIcon key="Courtesy car Bronze" />,
            ],
          },
          {
            label: (
              <ProductTableLabel>
                Cover whilst driving in Europe{' '}
                <ProductTableLabelSubText>
                  (Up to 90 days per trip, unlimited number of trips)
                </ProductTableLabelSubText>
              </ProductTableLabel>
            ),
            values: [
              <TickIcon key="Cover Europe Bronze" />,
              <TickIcon key="Cover Europe Silver" />,
              <TickIcon key="Cover Europe Bronze" />,
            ],
          },
          {
            label: (
              <ProductTableLabel>Personal accident cover</ProductTableLabel>
            ),
            values: [
              <ProductTableValue key="Personal accident Bronze">
                £1,000
              </ProductTableValue>,
              <ProductTableValue key="Personal accident Silver">
                £5,000
              </ProductTableValue>,
              <ProductTableValue key="Personal accident Gold">
                £10,000
              </ProductTableValue>,
            ],
          },
          {
            label: (
              <ProductTableLabel>Audio and satnav equipment</ProductTableLabel>
            ),
            values: [
              <CrossIcon key="Audio and satnav Bronze" />,
              <TickIcon key="Audio and satnav Silver" />,
              <TickIcon key="Audio and satnav Gold" />,
            ],
          },

          {
            label: <ProductTableLabel>Windscreen cover</ProductTableLabel>,
            values: [
              <CrossIcon key="Windscreen Bronze" />,
              <TickIcon key="Windscreen Silver" />,
              <TickIcon key="Windscreen Gold" />,
            ],
          },

          {
            label: (
              <ProductTableLabel>
                Uninsured driver promise{' '}
                <ProductTableLabelSubText>
                  (no excess to pay, & your no-claims discount is protected if
                  hit by an uninsured driver)
                </ProductTableLabelSubText>
              </ProductTableLabel>
            ),
            values: [
              <CrossIcon key="Uninsured driver Bronze" />,
              <TickIcon key="Uninsured driver Silver" />,
              <TickIcon key="Uninsured driver Gold" />,
            ],
          },
          {
            label: <ProductTableLabel>Child seat cover</ProductTableLabel>,
            values: [
              <CrossIcon key="Child seat Bronze" />,
              <ProductTableValue key="Child seat Silver">
                £100
              </ProductTableValue>,
              <ProductTableValue key="Child seat Gold">
                Unlimited
              </ProductTableValue>,
            ],
          },
          {
            label: <ProductTableLabel>Vandalism promise</ProductTableLabel>,
            values: [
              <CrossIcon key="Vandalism Bronze" />,
              <CrossIcon key="Vandalism Silver" />,
              <TickIcon key="Vandalism Gold" />,
            ],
          },
          {
            label: (
              <ProductTableLabel>Legal assistance cover</ProductTableLabel>
            ),
            values: [
              <CrossIcon key="Legal assistance Bronze" />,
              <CrossIcon key="Legal assistance Silver" />,
              <TickIcon key="Legal assistance Gold" />,
            ],
          },
          {
            label: (
              <ProductTableLabel>
                Roadside assistance breakdown cover
              </ProductTableLabel>
            ),
            values: [
              <CrossIcon key="Roadside assistance Bronze" />,
              <CrossIcon key="Roadside assistance Silver" />,
              <TickIcon key="Roadside assistance Gold" />,
            ],
          },
        ],
      },
    ],
  },
};

const policyDocuments: ProductBannerPolicyDocumentsDataType = {
  headerTitle: 'Policy wording',
  headerText:
    'Our policy wording has been reviewed by the Plain English Campaign, who champion policies that are crystal clear.',
  button: {
    label: 'Policy wording',
    url: '/car-insurance/policy-documents',
  },
};

const multiTabTableData: MultiTabTableData = {
  headerText: 'Create cover that suits you',
  subText:
    'Select exactly what you need and we’ll tailor the perfect policy for you.',
  tabs: [
    {
      title: 'Legal Assistance',
      extras: [
        {
          icon: <CourtSvg />,
          text: 'Up to £100,000 towards legal expenses to defend your rights',
        },
        {
          icon: <TickSvg />,
          text: 'Help with claiming back car insurance policy excess',
        },
        {
          icon: <CoinsSvg />,
          text: 'Help with settling car hire, purchase or sales disputes',
        },
        {
          icon: <NurseSvg />,
          text: 'Compensation for a personal injury',
        },
        {
          icon: <CarSvg />,
          text: 'Assistance with pot-hole damage, unenforceable parking fines and illegal clamping',
        },
      ],
    },
    {
      title: 'Breakdown cover',
      extras: [
        {
          icon: <TriangleSvg />,
          text: '24 hour assistance every day of the year',
        },
        {
          icon: <UkSvg />,
          text: 'Recovery anywhere in the UK',
        },
        {
          icon: <PhoneSvg />,
          text: 'Unlimited call outs',
        },
      ],
    },
    {
      title: 'Key cover',
      extras: [
        {
          icon: <CoinsSvg />,
          text: 'Up to £1500 of cover',
        },
        {
          icon: <ClockSvg />,
          text: '365/24/7 emergency helpline',
        },
        {
          icon: <KeySvg />,
          text: 'Cover for locksmith charges, replacement keys and locks, reprogramming immobilisers and travel costs',
        },
        {
          icon: <CoinsSvg />,
          text: 'A reward for the finder of your lost keys',
        },
      ],
    },
    {
      title: 'Replacement vehicle',
      extras: [
        {
          icon: <LockSvg />,
          text: 'Keep driving even if your car is written off',
        },
        {
          icon: <CarSvg />,
          text: 'Hire car for up to 21 days ',
        },
        {
          icon: <PhoneSvg />,
          text: '365/24/7 emergency helpline',
        },
      ],
    },
    {
      title: 'Personal accident cover',
      extras: [
        {
          icon: <UkSvg />,
          text: 'Up to £50,000 cover per person in the event of loss of limb, sight, speech or hearing',
        },
        {
          icon: <NurseSvg />,
          text: 'Up to £50,000 cover per person in the event of permanent disablement or death',
        },
        {
          icon: <CoinsSvg />,
          text: 'A maximum pay out of £250,000 per accident',
        },
        {
          icon: <NurseSvg />,
          text: 'In the event of hospitalisation, £100 per 24 hours in hospital',
        },
        {
          icon: <CoinsSvg />,
          text: 'Up to £500 for stress counselling',
        },
      ],
    },
    {
      title: 'Excess protection',
      extras: [
        {
          icon: <ScaleSvg />,
          text: 'Claim back your excess following the settlement of a claim',
        },
        {
          icon: <TriangleSvg />,
          text: 'Cover for accidental damage, vandalism, fire, flood, theft and attempted theft',
        },
        {
          icon: <CoinsSvg />,
          text: 'Choose from £350, £500 or £1,000 of cover',
        },
      ],
    },
  ],
};

const carInsurancePageDisclaimer = (
  <>
    <br />
    *The number of customers who reported savings when they switched their car
    insurance to Policy Expert, based on 98 survey responses carried out by
    QMetric Group Limited in May 2024.
  </>
);

const reviewsTitle = { headerText: 'People love what we do...' };

const CarInsuranceReviewsData: ReviewData[] = [
  {
    title: 'Easy and straight forward',
    author: 'Trevor Von Trilsbach',
    date: '03/04/2024',
    review:
      'Easy and straight forward to take up my Motor Insurance quote which was very competitively priced at £331.08. Would highly recommend Policy Expert for Motor and Home Insurance as I now have both policies with them.',
  },
  {
    title: 'Jolly good price',
    author: 'Joan',
    date: '09/04/2024',
    review:
      'Just spoke and answered all my questions in language I could understand, no rush and at the end a very good price which I was over the moon with.',
  },
  {
    title: 'Helpful and knowledgeable',
    author: 'Linda',
    date: '03/04/2024',
    review:
      'The operative I spoke to was polite helpful and very knowledgeable. The policy covers everything I need at a reasonable price.',
  },
  {
    title: 'Simple process',
    author: 'Dave, Aberdeenshire',
    date: '28/03/2024',
    review:
      "Very straightforward process online and documents all arrived by email promptly. I would certainly recommend taking insurance with this company - oh, and the cost was less than half the quote I got from my current insurer. I'm a very happy man.",
  },
];

const CarInsuranceReviews: JSX.Element[] = [];
CarInsuranceReviewsData.map((item) => {
  CarInsuranceReviews.push(
    <ReviewCard key={`carousel-${item.review}`} data={item} />
  );
});

const CarInsurancePage: React.FC = () => {
  const { isMobile, isDesktop } = useBreakpoint();

  const iconsRowBannerData: IconsBannerData = {
    bgColor: colors.white,
    headerText: (
      <IconBannerHeader>
        The kind of car
        <br />
        insurance you want
      </IconBannerHeader>
    ),
    icons: [
      {
        icon: <AssistanceIcon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>Uninsured driver promise.</SemiBoldSpan>
            {isDesktop ? <br /> : ' '}
            No excess to pay, & your no-claims discount is protected if hit by
            an uninsured driver.{' '}
            <IconBannerSmallText>
              (Silver & Gold cover only)
            </IconBannerSmallText>
          </IconBannerText>
        ),
      },
      {
        icon: <RepairWarrantyIcon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>3 year guarantee on repairs</SemiBoldSpan>{' '}
            {isDesktop ? <br /> : ' '}
            when you use one of our approved repairers.
          </IconBannerText>
        ),
      },
      {
        icon: <CompareChartIcon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>Three cover levels</SemiBoldSpan>
            {isDesktop ? <br /> : ' '}
            to suit your needs {isDesktop && <br />}and budget.  
          </IconBannerText>
        ),
      },
      {
        icon: <CoinsIcon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>Prices you’ll like</SemiBoldSpan>
            {isDesktop ? <br /> : ' '}
            because we only insure {isDesktop && <br />}low risk drivers.
          </IconBannerText>
        ),
      },
      {
        icon: <TowTruckIcon />,
        text: (
          <IconBannerText>
            A range of{' '}
            <SemiBoldSpan $isPurple>
              flexible {isDesktop && <br />}add-ons available.{' '}
            </SemiBoldSpan>
            {isDesktop && <br />}{' '}
            <IconBannerMoreInfo
              onClick={() => {
                scrollTo('#cover-section');
              }}
            >
              (More info)
            </IconBannerMoreInfo>
          </IconBannerText>
        ),
      },
      {
        icon: <OnlinePortalPurpleIcon />,
        text: (
          <IconBannerText>
            An online portal that {isDesktop && <br />}lets you
            <SemiBoldSpan $isPurple>
              {' '}
              manage your {isDesktop && <br />}policy 24/7.
            </SemiBoldSpan>
          </IconBannerText>
        ),
      },
    ],
  };

  useDisclaimer(carInsurancePageDisclaimer);

  const heroBannerData = {
    title: (
      <ProductPageHeaderBannerTitle>Car insurance</ProductPageHeaderBannerTitle>
    ),

    text: (
      <CarInsuranceHeaderText>
        Car customers{' '}
        <SemiBoldSpan $isPurple>saved an average of £166</SemiBoldSpan> when
        they joined us*
      </CarInsuranceHeaderText>
    ),
  };

  const headerImage = (
    <HeroBannerImageContainer>
      <StaticImage
        src="../images/car_insurance_hero.png"
        alt="Car insurance for people like you"
        layout="fullWidth"
        loading="eager"
        placeholder="blurred"
      />
    </HeroBannerImageContainer>
  );

  return (
    <OverflowContainer>
      <ImpactV2HeaderBanner
        headerImage={headerImage}
        backgroundColor="bg-pxTan"
        slantDirection="bottom-left"
        shapePosition={isMobile ? undefined : 'bottom-left-product-header'}
        heroBannerData={heroBannerData}
      />
      <ProductIconBanner data={iconsRowBannerData} hideShapeDesktop shape />
      <SlantedBackground
        backgroundColor={'bg-pxGrey'}
        margin="mb-1-minus"
        slantDirection={'top-right'}
        height
      />
      <ProductBannerContainer>
        <Shape position="top-right-product-table" />
        <ProductBanner
          tableData={productBannerData.productTableData}
          policyDocuments={policyDocuments}
          backgroundColor={colors.gray3}
        />
      </ProductBannerContainer>
      <SlantedBackground
        id="cover-section"
        backgroundColor={'bg-pxPurple'}
        margin="mb-1-minus"
        slantDirection={'top-left'}
        height
      />
      <MultiTabTable data={multiTabTableData} />
      <SlantedBackground
        backgroundColor={'bg-pxMint'}
        margin="mb-1-minus"
        slantDirection={'top-right'}
        height
      />
      <ReviewSection
        cards={CarInsuranceReviews}
        data={reviewsTitle}
        isHomePage
      />
    </OverflowContainer>
  );
};

export default CarInsurancePage;

export const Head: HeadFC = () => (
  <>
    <title>Car insurance for people like you</title>
    <meta
      name="description"
      content="Award winning car insurance from Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
