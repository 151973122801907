import { styled } from 'styled-components';

import { colors } from '../../styles/sharedStyled';

export const RenderProductSectionContainer = styled.div`
  padding-bottom: 50px;

  @media (min-width: 1024px) {
    padding-bottom: 0;
  }
`;

export const RenderProductSectionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 55px;
`;

export const RenderProductSectionIcon = styled.img<{
  $isHome?: boolean;
  $isPet?: boolean;
}>`
  width: ${({ $isHome, $isPet }) =>
    $isHome ? '50px' : $isPet ? '96px' : '77px'};
  border-width: 0px;
  display: block;
  margin: 0 auto;
`;

export const RenderProductSectionText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 23.28px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 24px;
`;

export const CarRegistrationInputContainer = styled.div<{ $hasText?: boolean }>`
  background-color: ${colors.yellow};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray7};
  margin: 10px auto;
  width: 240px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: stretch;
  box-sizing: border-box;
`;

export const CarRegistrationTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    background-color: ${colors.blue};
    border-width: 1px;
    border-style: solid;
    border-color: ${colors.gray7};
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: white;
    border-radius: 5px 0px 0px 5px;
    width: 50px;
  }
`;

export const CarRegistrationInput = styled.input`
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: black;
  width: 80%;

  &::placeholder {
    color: ${colors.gray16};
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;
