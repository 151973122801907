import { styled } from 'styled-components';

import Assistance from '../../static/svgIcons/Assistance.svg';
import Coins from '../../static/svgIcons/Coins.svg';
import Lock from '../../static/svgIcons/Lock.svg';
import Nurse from '../../static/svgIcons/Nurse.svg';
import RepairWarranty from '../../static/svgIcons/RepairWarranty.svg';
import Tick from '../../static/svgIcons/Tick.svg';
import TowTruck from '../../static/svgIcons/TowTruck.svg';
import Uk from '../../static/svgIcons/Uk.svg';
import { iconsBannerIconSize } from '../components/petIconsBanner/PetIconsBanner.style';
import { ProductPageHeaderBannerText, SvgColors } from '../styles/sharedStyled';

export const AssistanceIcon = styled(Assistance)`
  ${iconsBannerIconSize}
`;

export const RepairWarrantyIcon = styled(RepairWarranty)`
  ${iconsBannerIconSize}
`;

export const CoinsIcon = styled(Coins)`
  ${iconsBannerIconSize}
`;

export const TowTruckIcon = styled(TowTruck)`
  ${iconsBannerIconSize}
`;

export const TickSvg = styled(Tick)`
  ${SvgColors};
`;

export const NurseSvg = styled(Nurse)`
  ${SvgColors};
`;

export const UkSvg = styled(Uk)`
  ${SvgColors};
`;

export const LockSvg = styled(Lock)`
  ${SvgColors};
`;

export const CarInsuranceHeaderText = styled(ProductPageHeaderBannerText)`
  @media (min-width: 768px) {
    max-width: 440px;
    width: 320px;
  }

  @media (min-width: 1024px) {
    max-width: 480px;
    width: 100%;
  }
`;

export const CarInsuranceTextBanner = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  line-height: normal;

  @media (min-width: 1024px) {
    font-size: 25px;
  }
`;
