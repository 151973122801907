import React, { useCallback, useEffect, useState } from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import isEmpty from 'lodash/fp/isEmpty';

export type ShapePosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-left-cards'
  | 'bottom-right'
  | 'bottom-left-video'
  | 'bottom-left-header'
  | 'bottom-left-header-low'
  | 'bottom-left-header-long-mobile'
  | 'bottom-right-header'
  | 'bottom-right-header-claims'
  | 'bottom-mixed-header'
  | 'bottom-alternating-header'
  | 'bottom-left-product-table'
  | 'bottom-left-reviews'
  | 'bottom-icon-banner'
  | 'bottom-left-help'
  | 'bottom-left-cash-prize'
  | 'bottom-left-contact'
  | 'bottom-right-reviews'
  | 'bottom-right-terms'
  | 'bottom-left-header-V2'
  | 'bottom-left-product-header'
  | 'bottom-left-claims-header-V2'
  | 'bottom-left-pet-renewals'
  | 'bottom-left-pe-pets-banner'
  | 'bottom-right-pet-reviews'
  | 'bottom-what-is-it-text-banner'
  | 'bottom-questions-text-banner'
  | 'bottom-right-icon-banner'
  | 'bottom-left-renewals';

export interface ShapeType {
  section172?: boolean;
  position: ShapePosition;
  useSmallShape?: boolean;
}

const ShapeX = ({ position, useSmallShape }: ShapeType) => {
  const [style, setStyle] = useState<React.CSSProperties>({});
  const { isMobile, isTablet } = useBreakpoint();

  const sizes = {
    width: {
      largeShape: 1147,
      smallShape: 600,
    },
    height: {
      largeShape: 1123.8,
      smallShape: 587.87,
    },
  };

  const buildPositionStyle = useCallback(
    (
      shapePosition: ShapePosition,
      isMobileBreakpoint: boolean,
      isTabletBreakpoint: boolean,
      isSmallShape?: boolean
    ) => {
      switch (shapePosition) {
        case 'top-left':
          setStyle({
            ...style,
            transform: 'rotate(240deg)',
            left: isMobileBreakpoint
              ? `-${sizes.width.smallShape / 1.0}px`
              : `-${sizes.width.smallShape / 1.1}px`,
            top: isMobileBreakpoint
              ? `-${sizes.height.smallShape / 1.4}px`
              : `-${sizes.width.smallShape / 0.7}px`,
          });
          break;
        case 'top-right':
          setStyle({
            ...style,
            transform: 'rotate(303deg)',
            right: isMobileBreakpoint
              ? `-${sizes.width.smallShape / 1.9}px`
              : `-${sizes.width.smallShape / 0.9}px`,
            top: isMobileBreakpoint
              ? `-${sizes.height.smallShape / 1.4}px`
              : `-${sizes.width.smallShape / 0.75}px`,
          });
          break;
        case 'bottom-left':
          setStyle({
            ...style,
            transform: 'rotate(120deg)',
            left:
              isMobileBreakpoint || isSmallShape
                ? `-${sizes.width.smallShape / 1.7}px`
                : `-${sizes.width.smallShape / 1}px`,
            bottom:
              isMobileBreakpoint || isSmallShape
                ? `-${sizes.height.smallShape / 1.3}px`
                : `-${sizes.width.smallShape / 0.7}px`,
          });
          break;
        case 'bottom-left-cash-prize':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '339px'
              : isTabletBreakpoint
              ? '780px'
              : '800px',
            width: isMobileBreakpoint
              ? '347px'
              : isTabletBreakpoint
              ? '790px'
              : '819px',
            transform: 'rotate(127.571deg)',
            right: '',
            left: isMobileBreakpoint ? '-220px' : '-499px',
            bottom: isMobileBreakpoint ? '-230px' : '-560px',
          });
          break;
        case 'bottom-left-cards':
          setStyle({
            ...style,
            transform: 'rotate(115deg)',
            left: isMobileBreakpoint
              ? `-${sizes.width.smallShape / 1.7}px`
              : `-${sizes.width.smallShape / 0.9}px`,
            bottom: isMobileBreakpoint
              ? `-${sizes.height.smallShape / 1.3}px`
              : `-${sizes.width.smallShape / 0.69}px`,
          });
          break;
        case 'bottom-left-video':
          setStyle({
            ...style,
            transform: 'rotate(120deg)',
            left: isMobileBreakpoint
              ? `-${sizes.width.smallShape / 1.7}px`
              : `-${sizes.width.smallShape / 1}px`,
            bottom: isMobileBreakpoint
              ? `-${sizes.height.smallShape / 1.3}px`
              : `-${sizes.width.smallShape / 0.75}px`,
          });
          break;
        case 'bottom-right':
          setStyle({
            ...style,
            transform: 'rotate(60deg)',
            right: isMobileBreakpoint
              ? `-${sizes.width.smallShape / 1.9}px`
              : `-${sizes.width.smallShape / 1.1}px`,
            bottom: isMobileBreakpoint
              ? `-${sizes.height.smallShape / 1.4}px`
              : `-${sizes.width.smallShape / 0.7}px`,
          });
          break;
        case 'bottom-left-header':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '331px'
              : isTabletBreakpoint
              ? '614px'
              : '816px',
            width: isMobileBreakpoint
              ? '391px'
              : isTabletBreakpoint
              ? '658px'
              : '836px',
            transform: 'rotate(123.24deg)',
            left: isMobileBreakpoint
              ? '-179px'
              : isTabletBreakpoint
              ? '-314px'
              : '-475px',
            bottom: isMobileBreakpoint
              ? '-262px'
              : isTabletBreakpoint
              ? '-458px'
              : '-592px',
          });
          break;
        case 'bottom-left-header-low':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '288px'
              : isTabletBreakpoint
              ? '614px'
              : '816px',
            width: isMobileBreakpoint
              ? '295px'
              : isTabletBreakpoint
              ? '658px'
              : '836px',
            transform: isMobileBreakpoint
              ? 'rotate(119.62deg)'
              : isTabletBreakpoint
              ? 'rotate(119.87deg)'
              : 'rotate(123.24deg)',
            left: isMobileBreakpoint
              ? '-152px'
              : isTabletBreakpoint
              ? '-348px'
              : '-475px',
            bottom: isMobileBreakpoint
              ? '-207px'
              : isTabletBreakpoint
              ? '-471px'
              : '-592px',
          });
          break;
        case 'bottom-left-header-long-mobile':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '417px'
              : isTabletBreakpoint
              ? '614px'
              : '816px',
            width: isMobileBreakpoint
              ? '428px'
              : isTabletBreakpoint
              ? '658px'
              : '836px',
            transform: isMobileBreakpoint
              ? 'rotate(118deg)'
              : isTabletBreakpoint
              ? 'rotate(119.87deg)'
              : 'rotate(123.24deg)',
            left: isMobileBreakpoint
              ? '-235px'
              : isTabletBreakpoint
              ? '-348px'
              : '-475px',
            bottom: isMobileBreakpoint
              ? '-310px'
              : isTabletBreakpoint
              ? '-471px'
              : '-592px',
          });
          break;
        case 'bottom-right-header':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '340px'
              : isTabletBreakpoint
              ? '557px'
              : '913px',
            width: isMobileBreakpoint
              ? '348px'
              : isTabletBreakpoint
              ? '570px'
              : '935px',
            transform: isMobileBreakpoint
              ? 'rotate(59.21deg)'
              : isTabletBreakpoint
              ? 'rotate(57.02deg)'
              : 'rotate(58.96deg)',
            right: isMobileBreakpoint
              ? '-207px'
              : isTabletBreakpoint
              ? '-335px'
              : '-525px',
            bottom: isMobileBreakpoint
              ? '-251px'
              : isTabletBreakpoint
              ? '-394px'
              : '-643px',
          });
          break;
        case 'bottom-right-header-claims':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '297px'
              : isTabletBreakpoint
              ? '515px'
              : '699px',
            width: isMobileBreakpoint
              ? '304px'
              : isTabletBreakpoint
              ? '528px'
              : '716px',
            transform: isMobileBreakpoint
              ? 'rotate(60.891deg)'
              : isTabletBreakpoint
              ? 'rotate(57.868deg)'
              : 'rotate(60.687deg)',
            right: isMobileBreakpoint
              ? '-175px'
              : isTabletBreakpoint
              ? '-295px'
              : '-385px',
            bottom: isMobileBreakpoint
              ? '-220px'
              : isTabletBreakpoint
              ? '-360px'
              : '-500px',
          });
          break;
        case 'bottom-mixed-header':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '288px'
              : isTabletBreakpoint
              ? '614px'
              : '816px',
            width: isMobileBreakpoint
              ? '295px'
              : isTabletBreakpoint
              ? '658px'
              : '836px',
            transform: isMobileBreakpoint
              ? 'rotate(119.62deg)'
              : isTabletBreakpoint
              ? 'rotate(119.87deg)'
              : 'rotate(56.97deg)',
            right: isMobileBreakpoint ? '' : isTabletBreakpoint ? '' : '-500px',
            left: isMobileBreakpoint
              ? '-152px'
              : isTabletBreakpoint
              ? '-348px'
              : '',
            bottom: isMobileBreakpoint
              ? '-207px'
              : isTabletBreakpoint
              ? '-471px'
              : '-583px',
          });
          break;
        case 'bottom-alternating-header':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '360px'
              : isTabletBreakpoint
              ? '563px'
              : '816px',
            width: isMobileBreakpoint
              ? '369px'
              : isTabletBreakpoint
              ? '577px'
              : '836px',
            transform: isMobileBreakpoint
              ? 'rotate(125.06deg)'
              : isTabletBreakpoint
              ? 'rotate(57.06deg)'
              : 'rotate(123.24deg)',
            right: isMobileBreakpoint ? '' : isTabletBreakpoint ? '-350px' : '',
            left: isMobileBreakpoint
              ? '-230px'
              : isTabletBreakpoint
              ? ''
              : '-475px',
            bottom: isMobileBreakpoint
              ? '-225px'
              : isTabletBreakpoint
              ? '-415px'
              : '-592px',
          });
          break;
        case 'bottom-icon-banner':
          isMobileBreakpoint
            ? setStyle({
                ...style,
                height: '482px',
                width: '494px',
                transform: 'rotate(59.055deg)',
                right: '-290px',
                bottom: '-340px',
                left: '',
              })
            : setStyle({
                ...style,
                height: '894px',
                width: '916px',
                transform: 'rotate(123.244deg)',
                left: '-530px',
                bottom: '-565px',
              });
          break;
        case 'bottom-left-reviews':
          setStyle({
            ...style,
            height: '549px',
            width: '562px',
            transform: 'rotate(116.941deg)',
            left: '-290px',
            bottom: '-390px',
            right: '',
          });
          break;
        case 'bottom-right-reviews':
          setStyle({
            ...style,
            height: '1259px',
            width: '1290px',
            transform: 'rotate(61.832deg)',
            right: '-600px',
            bottom: '-920px',
            left: '',
          });
          break;
        case 'bottom-left-product-table':
          setStyle({
            ...style,
            height: '549px',
            width: '562px',
            transform: 'rotate(116.941deg)',
            left: '-270px',
            bottom: '-375px',
          });
          break;
        case 'bottom-left-help':
          setStyle({
            ...style,
            height: isMobileBreakpoint ? '422px' : '549px',
            width: isMobileBreakpoint ? '433px' : '562px',
            transform: isMobileBreakpoint
              ? 'rotate(120.483deg)'
              : 'rotate(116.941deg)',
            left: isMobileBreakpoint ? '-250px' : '-290px',
            bottom: isMobileBreakpoint ? '-300px' : '-380px',
            zIndex: '-1',
          });
          break;
        case 'bottom-left-contact':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '487px'
              : isTabletBreakpoint
              ? '666px'
              : '1065px',
            width: isMobileBreakpoint
              ? '499px'
              : isTabletBreakpoint
              ? '786px'
              : '1090px',
            transform: 'rotate(118.642deg)',
            left: isMobileBreakpoint
              ? '-290px'
              : isTabletBreakpoint
              ? '-415px'
              : '-600px',
            bottom: isMobileBreakpoint
              ? '-365px'
              : isTabletBreakpoint
              ? '-545px'
              : '-785px',
          });
          break;
        case 'bottom-right-terms':
          setStyle({
            ...style,
            height: isMobileBreakpoint ? '611px' : '1213px',
            width: isMobileBreakpoint ? '625px' : '1241px',
            transform: isMobileBreakpoint
              ? 'rotate(54.92deg)'
              : 'rotate(59.102deg)',
            right: isMobileBreakpoint ? '-410px' : '-740px',
            bottom: isMobileBreakpoint ? '-445px' : '-900px',
          });
          break;
        case 'bottom-left-header-V2':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '331px'
              : isTabletBreakpoint
              ? '515px'
              : '785px',
            width: isMobileBreakpoint
              ? '391px'
              : isTabletBreakpoint
              ? '528px'
              : '805px',
            transform: 'rotate(116.7deg)',
            left: isMobileBreakpoint
              ? '-179px'
              : isTabletBreakpoint
              ? '-290px'
              : '-400px',
            bottom: isMobileBreakpoint
              ? '-262px'
              : isTabletBreakpoint
              ? '-383px'
              : '-565px',
          });
          break;
        case 'bottom-left-product-header':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '331px'
              : isTabletBreakpoint
              ? '515px'
              : '710px',
            width: isMobileBreakpoint
              ? '391px'
              : isTabletBreakpoint
              ? '528px'
              : '727px',
            transform: 'rotate(116.7deg)',
            left: isMobileBreakpoint
              ? '-179px'
              : isTabletBreakpoint
              ? '-290px'
              : '-375px',
            bottom: isMobileBreakpoint
              ? '-262px'
              : isTabletBreakpoint
              ? '-383px'
              : '-525px',
          });
          break;
        case 'bottom-left-claims-header-V2':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '297px'
              : isTabletBreakpoint
              ? '515px'
              : '699px',
            width: isMobileBreakpoint
              ? '304px'
              : isTabletBreakpoint
              ? '528px'
              : '716px',
            transform: 'rotate(119.1deg)',
            left: isMobileBreakpoint
              ? '-165px'
              : isTabletBreakpoint
              ? '-281px'
              : '-381px',
            bottom: isMobileBreakpoint
              ? '-213px'
              : isTabletBreakpoint
              ? '-376px'
              : '-497px',
          });
          break;
        case 'bottom-left-pet-renewals':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '297px'
              : isTabletBreakpoint
              ? '515px'
              : '699px',
            width: isMobileBreakpoint
              ? '304px'
              : isTabletBreakpoint
              ? '528px'
              : '716px',
            transform: isMobileBreakpoint
              ? 'rotate(119.109deg)'
              : isTabletBreakpoint
              ? 'rotate(116.7deg)'
              : 'rotate(119.313deg)',
            left: isMobileBreakpoint
              ? '-165px'
              : isTabletBreakpoint
              ? '-280px'
              : '-380px',
            bottom: isMobileBreakpoint
              ? '-213px'
              : isTabletBreakpoint
              ? '-375px'
              : '-500px',
          });
          break;
        case 'bottom-left-pe-pets-banner':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '482px'
              : isTabletBreakpoint
              ? '515px'
              : '785px',
            width: isMobileBreakpoint
              ? '494px'
              : isTabletBreakpoint
              ? '528px'
              : '804px',
            transform: isMobileBreakpoint
              ? 'rotate(120.945deg)'
              : isTabletBreakpoint
              ? 'rotate(116.7deg)'
              : 'rotate(116.699deg)',
            left: isMobileBreakpoint
              ? '-310px'
              : isTabletBreakpoint
              ? '-295px'
              : '-405px',
            bottom: isMobileBreakpoint
              ? '-345px'
              : isTabletBreakpoint
              ? '-355px'
              : '-540px',
          });
          break;
        case 'bottom-right-pet-reviews':
          setStyle({
            ...style,
            height: isMobileBreakpoint ? '438px' : '1035px',
            width: isMobileBreakpoint ? '449px' : '1010px',
            transform: isMobileBreakpoint
              ? 'rotate(56.477deg)'
              : 'rotate(60deg)',
            right: isMobileBreakpoint ? '-250px' : '-490px',
            bottom: isMobileBreakpoint ? '-305px' : '-760px',
            display: isMobileBreakpoint
              ? 'block'
              : isTabletBreakpoint
              ? 'none'
              : 'block',
          });
          break;
        case 'bottom-what-is-it-text-banner':
          isMobileBreakpoint
            ? setStyle({
                ...style,
                display: 'block',
                height: '482px',
                width: '494px',
                transform: 'rotate(59.055deg)',
                right: '-299px',
                bottom: '-353px',
                left: '',
              })
            : setStyle({
                ...style,
                display: 'none',
              });
          break;
        case 'bottom-questions-text-banner':
          setStyle({
            ...style,
            height: isMobileBreakpoint
              ? '482px'
              : isTabletBreakpoint
              ? '549px'
              : '785px',
            width: isMobileBreakpoint
              ? '494px'
              : isTabletBreakpoint
              ? '562px'
              : '804px',
            transform: isMobileBreakpoint
              ? 'rotate(59.055deg)'
              : isTabletBreakpoint
              ? 'rotate(63.059deg)'
              : 'rotate(64deg)',
            right: isMobileBreakpoint
              ? '-298px'
              : isTabletBreakpoint
              ? '-289px'
              : '-400px',
            bottom: isMobileBreakpoint
              ? '-358px'
              : isTabletBreakpoint
              ? '-407px'
              : '-579px',
          });
          break;
        case 'bottom-right-icon-banner':
          setStyle({
            ...style,
            height: '894px',
            width: '916px',
            transform: 'rotate(60deg)',
            right: '-505px',
            bottom: '-625px',
            left: '',
          });
          break;
        case 'bottom-left-renewals':
          setStyle({
            ...style,
            height: '920px',
            width: '980px',
            transform: 'rotate(120deg)',
            left: '-515px',
            bottom: '-705px',
          });
          break;
        default:
          break;
      }
    },
    [setStyle, style]
  );

  useEffect(() => {
    buildPositionStyle(position, isMobile, isTablet, useSmallShape);
  }, [position, isMobile, isTablet, useSmallShape]);

  if (isEmpty(style)) {
    return null;
  }

  return (
    <div
      className={`absolute w-smallShape h-smallShape ${
        useSmallShape ? '' : 'md:w-largeShape md:h-largeShape'
      } -z-10`}
      style={style}
      data-testid="shape-x"
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 151.91 148.84"
      >
        <g id="Graphic-Shape">
          <path
            id="Fill-7"
            d="M114.18,52.66,76,28.89,37.73,52.66c-15.55,9.67-15.55,33.85,0,43.52L76,119.94l38.23-23.76c15.55-9.67,15.55-33.85,0-43.52"
            transform="translate(0 0)"
            style={{ fill: '#ed239e', fillRule: 'evenodd' }}
          />
          <path
            id="Fill-9"
            d="M114.18,52.66,76,28.89,37.73,52.66c-15.55,9.67-15.55,33.85,0,43.52L76,119.94l38.23-23.76c15.55-9.67,15.55-33.85,0-43.52"
            transform="translate(0 0)"
            style={{ fill: '#620077', fillRule: 'evenodd' }}
          />
          <path
            id="Fill-1"
            d="M37.73,52.66,76,28.89,60.3,19.16,35.09,3.49C15.38-8.77-7.29,13.52,2.25,35.78L14.3,63.88A26.86,26.86,0,0,1,14.3,85l-12,28.11c-9.54,22.25,13.13,44.55,32.84,32.29L60.3,129.68,76,119.94,37.73,96.18c-15.55-9.67-15.55-33.85,0-43.52"
            transform="translate(0 0)"
            style={{ fill: '#ff00a0', fillRule: 'evenodd' }}
          />
          <path
            id="Fill-4"
            d="M137.61,85a26.86,26.86,0,0,1,0-21.07l12-28.1c9.54-22.26-13.13-44.55-32.85-32.29L91.61,19.16,76,28.89l38.23,23.77c15.55,9.67,15.55,33.85,0,43.52L76,119.94l15.66,9.74,25.2,15.67c19.72,12.26,42.39-10,32.85-32.29Z"
            transform="translate(0 0)"
            style={{ fill: '#6214be', fillRule: 'evenodd' }}
          />
        </g>
      </svg>
    </div>
  );
};

export default ShapeX;
