import { Link } from 'gatsby';
import { styled } from 'styled-components';

import { colors, fontFamilyCamptonSemiBold } from '../../styles/sharedStyled';

export const SubmittedContainer = styled.div`
  padding-bottom: 208px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubmittedHeader = styled.h1`
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const SubmittedText = styled.p`
  width: 90%;
  text-align: center;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
`;

export const ReturnToHomeLink = styled(Link)<{ $isRafPage?: boolean }>`
  width: 100%;
  font-size: 16px;
  height: 50px;
  font-family: ${fontFamilyCamptonSemiBold};
  border-radius: 15px;
  border: 2px solid ${colors.pink2};
  background-color: ${colors.pink2};
  color: ${colors.white};
  margin-top: 50px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  text-align: center;
  padding: 13px;

  &:hover {
    background-color: ${colors.white};
    color: ${colors.pink2};
  }

  @media (min-width: 768px) {
    width: 300px;
    height: 68px;
    padding: 20px;
    font-size: 20px;
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 60px;
  }
`;
