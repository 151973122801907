import React from 'react';

import { HeadFC } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

import ImpactV2HeaderBanner from '../components/headerBanner/HeaderBannerV2';
import PetInsuranceReviews from '../components/insuranceReviews/PetInsuranceReviews';
import MultiTabTable, {
  MultiTabTableData,
} from '../components/multiTabTable/MultiTabTable';
import PetBlueCrossBanner from '../components/petBlueCrossBanner/PetBlueCrossBanner';
import ProductIconBanner, {
  IconsBannerData,
} from '../components/petIconsBanner/PetIconsBanner';
import {
  IconBannerHeader,
  IconBannerText,
  ImageContainer,
  MonthlyIcon,
  PetCallCentreIcon,
  PetClaimsIcon,
  PetExpertsIcon,
  PetRatedNo1Icon,
  VetBackgroundIcon,
} from '../components/petIconsBanner/PetIconsBanner.style';
import PetProductBanner from '../components/petProductBanner/PetProductBanner';
import {
  ProductBannerText,
  ProductBannerTitle,
} from '../components/petProductBanner/PetProductBanner.style';
import {
  CrossIcon,
  PetBronzeIcon,
  PetGoldIcon,
  PetSilverIcon,
  ProductTableHeader,
  ProductTableHeaderSpan,
  ProductTableLabel,
  ProductTableValue,
  TickIcon,
} from '../components/petProductTable/PetProductTable.style';
import PolicyExpertPetsBanner from '../components/policyExpertPetsBanner/PolicyExpertPetsBanner';
import { ProductBannerPolicyDocumentsDataType } from '../components/productBannerPolicyDocuments/ProductBannerPolicyDocuments';
import PetReviewBanner from '../components/reviewBanner/PetReviewBanner';
import Shape from '../components/Shape';
import SlantedBackground from '../components/slantedBackground/SlantedBackground';
import useDisclaimer from '../hooks/useDisclaimer';
import usePetChanges from '../hooks/usePetChanges';
import {
  colors,
  HeroBannerImageContainer,
  ProductPageHeaderBannerText,
  ProductPageHeaderBannerTitle,
  SemiBoldSpan,
} from '../styles/sharedStyled';
import NotFoundPage from './404';
import {
  CalendarIcon,
  ChartIcon,
  ClaimsIcon,
  CoinsIcon,
  LostPetIcon,
  MedicineIcon,
  PetTableBannerContainer,
  PlaneIcon,
  UrnIcon,
  VetIcon,
} from './pet-insurance.style';

const disclaimerData = (
  <>
    <br />
    *The number of customers insured by Policy Expert - July 2024.
    <br />
    **81% of staff in our Milton Keynes Office, own or will own a pet in the
    very near future - survery completed February 2024.
  </>
);

const heroBannerData = {
  title: (
    <ProductPageHeaderBannerTitle>Pet insurance</ProductPageHeaderBannerTitle>
  ),

  text: (
    <ProductPageHeaderBannerText>
      <SemiBoldSpan $isPurple>
        Lifetime cover made simple.
        <br />
      </SemiBoldSpan>
      Giving you more time to focus on
      <br />
      what matters most.
    </ProductPageHeaderBannerText>
  ),
};

const headerImage = (
  <HeroBannerImageContainer $isPet>
    <StaticImage
      src="../images/pet_insurance_hero.png"
      alt="Pet insurance for people like you"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
      objectFit="contain"
    />
  </HeroBannerImageContainer>
);

const policyDocuments: ProductBannerPolicyDocumentsDataType = {
  headerTitle: 'Policy wording',
  headerText:
    'Our policy wording has been reviewed by the Plain English Campaign, who champion policies that are crystal clear.',
  button: {
    label: 'Policy wording',
    url: '/pet-insurance/policy-documents',
  },
};

const productBannerData = {
  bannerTitle: <ProductBannerTitle>Pick of the litter</ProductBannerTitle>,
  bannerText: (
    <ProductBannerText>
      Your best friend deserves the best. That's why we only offer{' '}
      <SemiBoldSpan $isPurple>lifetime cover,</SemiBoldSpan> to help give you
      <SemiBoldSpan $isPurple> complete peace of mind.</SemiBoldSpan>
    </ProductBannerText>
  ),
  tableData: {
    tableHeaderIcons: [
      <PetBronzeIcon key="PetBronzeIcon" />,
      <PetSilverIcon key="PetSilverIcon" />,
      <PetGoldIcon key="PetGoldIcon" />,
    ],
    tableContent: [
      {
        header: <ProductTableHeader>Treatments</ProductTableHeader>,
        productRows: [
          {
            label: <ProductTableLabel>Vet fees</ProductTableLabel>,
            values: [
              <ProductTableValue key="Vet fees Bronze">
                £2,000
              </ProductTableValue>,
              <ProductTableValue key="Vet fees Silver">
                £5,000
              </ProductTableValue>,
              <ProductTableValue key="Vet fees Gold">
                £10,000
              </ProductTableValue>,
            ],
          },
          {
            label: (
              <ProductTableLabel>Complementary treatment</ProductTableLabel>
            ),
            values: [
              <CrossIcon key="Complementary treatment Bronze" />,
              <ProductTableValue key="Complementary treatment Silver">
                £1,000
              </ProductTableValue>,
              <ProductTableValue key="Complementary treatment Gold">
                £2,000
              </ProductTableValue>,
            ],
          },
          {
            label: <ProductTableLabel>Behavioural treatment</ProductTableLabel>,
            values: [
              <CrossIcon key="Behavioural treatment Bronze" />,
              <ProductTableValue key="Behavioural treatment Silver">
                £1,000
              </ProductTableValue>,
              <ProductTableValue key="Behavioural treatment Gold">
                £1,000
              </ProductTableValue>,
            ],
          },
          {
            label: <ProductTableLabel>Dental care</ProductTableLabel>,
            values: [
              <CrossIcon key="Dental care Bronze" />,
              <ProductTableValue key="Dental care Silver">
                £2,000
                <br />
                accident only
              </ProductTableValue>,
              <ProductTableValue key="Dental care Gold">
                £2,000
                <br />
                accident and illness
              </ProductTableValue>,
            ],
          },
        ],
      },
      {
        header: (
          <ProductTableHeader>
            Liability{' '}
            <ProductTableHeaderSpan>(dogs only)</ProductTableHeaderSpan>
          </ProductTableHeader>
        ),
        productRows: [
          {
            label: <ProductTableLabel>Third party liability</ProductTableLabel>,
            values: [
              <ProductTableValue key="Third party liability Bronze">
                £500,000
              </ProductTableValue>,
              <ProductTableValue key="Third party liability Silver">
                £1,000,000
              </ProductTableValue>,
              <ProductTableValue key="Third party liability Gold">
                £2,000,000
              </ProductTableValue>,
            ],
          },
        ],
      },
      {
        header: <ProductTableHeader>Other covers</ProductTableHeader>,
        productRows: [
          {
            label: (
              <ProductTableLabel>Emergency boarding/minding</ProductTableLabel>
            ),
            values: [
              <CrossIcon key="Emergency boarding/minding Bronze" />,
              <ProductTableValue key="Emergency boarding/minding Silver">
                Up to £600
              </ProductTableValue>,
              <ProductTableValue key="Emergency boarding/minding Gold">
                Up to £1,200
              </ProductTableValue>,
            ],
          },
          {
            label: <ProductTableLabel>Missing pet</ProductTableLabel>,
            values: [
              <CrossIcon key="Missing pet Bronze" />,
              <TickIcon key="Missing pet Silver" />,
              <TickIcon key="Missing pet Gold" />,
            ],
          },
          {
            label: <ProductTableLabel>Holiday cover</ProductTableLabel>,

            values: [
              <CrossIcon key="Holiday cover Bronze" />,
              <ProductTableValue key="Holiday cover Silver">
                Optional
              </ProductTableValue>,
              <TickIcon key="Holiday cover Gold" />,
            ],
          },
          {
            label: <ProductTableLabel>Farewell cover</ProductTableLabel>,
            values: [
              <CrossIcon key="Farewell cover Bronze" />,
              <ProductTableValue key="Farewell cover Silver">
                Optional
              </ProductTableValue>,
              <ProductTableValue key="Farewell cover Gold">
                Optional
              </ProductTableValue>,
            ],
          },
        ],
      },
      {
        header: <ProductTableHeader>Other features</ProductTableHeader>,
        productRows: [
          {
            label: <ProductTableLabel>24hr vet advice line</ProductTableLabel>,
            values: [
              <TickIcon key="Other features Bronze" />,
              <TickIcon key="Other features Silver" />,
              <TickIcon key="Other features Gold" />,
            ],
          },
          {
            label: (
              <ProductTableLabel>Pet loss support services</ProductTableLabel>
            ),
            values: [
              <TickIcon key="Pet loss support services Bronze" />,
              <TickIcon key="Pet loss support services Silver" />,
              <TickIcon key="Pet loss support services Gold" />,
            ],
          },
        ],
      },
    ],
  },
};

const multiTabTableData: MultiTabTableData = {
  headerText: 'Cover for the pet you love',
  subText:
    "Nobody knows your pet better than you do. Select exactly what you need, and we'll tailor the perfect policy. Simple.",
  tabs: [
    {
      title: 'Holiday cover',
      extras: [
        {
          icon: <VetIcon />,
          text: 'Vet fees whilst travelling abroad with your pet',
        },
        {
          icon: <PlaneIcon />,
          text: 'Staying abroad longer because your pet needs emergency treatment',
        },
        {
          icon: <CalendarIcon />,
          text: 'Cover if your trip gets cancelled because of your pet',
        },
        {
          icon: <ClaimsIcon />,
          text: 'Quarantine expenses up to £1,000',
        },
        {
          icon: <ChartIcon />,
          text: 'Costs incurred to replace a lost healthcare certificate',
        },
        {
          icon: <MedicineIcon />,
          text: 'Up to £250 for a repeat course of tapeworm treatment',
        },
      ],
    },
    {
      title: 'Farewell cover',
      extras: [
        {
          icon: <CoinsIcon />,
          text: "We'll reimburse the amount you paid or donated if your pet passes away",
        },
        {
          icon: <LostPetIcon />,
          text: "We'll pay back the amount you paid or donated - if your pet goes missing and isn't found",
        },
        {
          icon: <UrnIcon />,
          text: "We'll help you say goodbye. We'll pay up to £250 towards the cost of a cremation or burial for your pet",
        },
      ],
    },
  ],
};

const reviewsTitle = { headerText: 'People love what we do...' };

const PetInsurancePage = () => {
  const { isMobile } = useBreakpoint();
  const isPetChangesEnabled = usePetChanges();

  useDisclaimer(disclaimerData);

  const iconsRowBannerData: IconsBannerData = {
    bgColor: colors.white,
    image: (
      <ImageContainer>
        <StaticImage
          src="./../images/cat_looking.png"
          alt="Cat Looking up"
          layout="fullWidth"
          loading="eager"
          placeholder="blurred"
        />
      </ImageContainer>
    ),
    headerText: (
      <IconBannerHeader>
        The kind of insurance
        {isMobile ? ' ' : <br />}
        you want for your pet
      </IconBannerHeader>
    ),
    icons: [
      {
        icon: <VetBackgroundIcon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>24/7 vet advice</SemiBoldSpan> is only a
            call away, day or night we’re here to help.
          </IconBannerText>
        ),
      },
      {
        icon: <PetRatedNo1Icon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>
              Trusted by over 1.5 million customers*
            </SemiBoldSpan>{' '}
            and now we can cover your pets.
          </IconBannerText>
        ),
      },
      {
        icon: <MonthlyIcon />,
        text: (
          <IconBannerText>
            Pay monthly
            <br />
            <SemiBoldSpan $isPurple> at no extra cost.</SemiBoldSpan>
          </IconBannerText>
        ),
      },
      {
        icon: <PetClaimsIcon />,
        text: (
          <IconBannerText>
            <SemiBoldSpan $isPurple>Claims made simpler.</SemiBoldSpan> We can
            pay your vet directly, so it's one less thing to worry about.{' '}
          </IconBannerText>
        ),
      },
      {
        icon: <PetCallCentreIcon />,
        text: (
          <IconBannerText>
            Speak to real people about your policy in our
            <SemiBoldSpan $isPurple> UK-based call centre.</SemiBoldSpan>
          </IconBannerText>
        ),
      },

      {
        icon: <PetExpertsIcon />,
        text: (
          <IconBannerText>
            Our online articles are
            <SemiBoldSpan $isPurple> written by experts</SemiBoldSpan> to help
            keep happy pets healthy.
          </IconBannerText>
        ),
      },
    ],
  };

  return !isPetChangesEnabled ? (
    // @ts-ignore
    <NotFoundPage />
  ) : (
    <div className="overflow-hidden">
      <ImpactV2HeaderBanner
        headerImage={headerImage}
        backgroundColor="bg-pxGrey"
        slantDirection="bottom-left"
        shapePosition={isMobile ? undefined : 'bottom-left-product-header'}
        heroBannerData={heroBannerData}
      />
      <ProductIconBanner data={iconsRowBannerData} hideShapeDesktop shape />
      <SlantedBackground
        backgroundColor="bg-pxTan"
        margin="mb-1-minus"
        slantDirection="top-right"
        height
      />
      <PetTableBannerContainer>
        <Shape position="top-right-product-table" />
        <PetProductBanner
          backgroundColor={colors.tan1}
          productBannerData={productBannerData}
          policyDocuments={policyDocuments}
        />
      </PetTableBannerContainer>
      <SlantedBackground
        backgroundColor="bg-pxPurple"
        margin="mb-1-minus"
        slantDirection="top-left"
        height
      />
      <MultiTabTable data={multiTabTableData} />
      <SlantedBackground
        backgroundColor="bg-pxGrey"
        margin="mb-1-minus"
        slantDirection="top-right"
        height
      />
      <PolicyExpertPetsBanner />
      <SlantedBackground
        backgroundColor="bg-pxWhite"
        margin="mb-1-minus"
        slantDirection="top-left"
        height
      />
      <PetBlueCrossBanner />
      <SlantedBackground
        backgroundColor="bg-pxMint"
        margin="mb-1-minus"
        slantDirection="top-right"
        height
      />
      <PetReviewBanner cards={PetInsuranceReviews} data={reviewsTitle} />
    </div>
  );
};

export default PetInsurancePage;

export const Head: HeadFC = () => (
  <>
    <title>Pet insurance for people like you</title>
    <meta
      name="description"
      content="Pet insurance from Policy Expert - insurance for people like you."
    />
    <html lang="en" />
  </>
);
