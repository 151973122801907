import React, { useEffect, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import { HeadFC, navigate, PageProps } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { v4 as uuidv4 } from 'uuid';

import renderProductSection from '../../components/capture/RenderProductSection';
import Shape from '../../components/Shape';
import ShapeX from '../../components/ShapeX';
import Submission from '../../components/submission/Submission';
import useDisclaimer from '../../hooks/useDisclaimer';
import useQueryParams from '../../hooks/useQueryParams';
import {
  ProductSectionButton,
  ProductSectionContainer,
  RenewalCaptureContainer,
  RenewalCaptureContentWrapper,
  RenewalCaptureHeader,
  RenewalCaptureText,
  RenewalCaptureTextWrapper,
} from './renewals.style';

const CaptureRenewalsPage: React.FC<PageProps> = () => {
  useDisclaimer(null);
  const { handleSubmit, reset } = useFormContext<FormValues>();
  const [submitted, isSubmitted] = useState(false);
  const { product, month } = useQueryParams();
  const today = dayjs.tz(Date.now(), 'Europe/London').format('DD/MM/YYYY');
  const unixTimestamp = dayjs().unix();
  const { isDesktop } = useBreakpoint();

  type FormValues = {
    fhins_xsell_car_month: string;
    fhins_xsell_number_plate: string;
    fhins_xsell_home_month: string;
    fhins_xsell_pet_month: string;
  };

  const submitDataToDataLayer: SubmitHandler<FormValues> = (data) => {
    const homeDates = {
      namespace: 'exponea',
      event_name: 'xsell_home_dates',
      event_properties: {
        UID: uuidv4(),
        xsell_home_month: data.fhins_xsell_home_month,
        xsell_home_collected_date: today,
        xsell_home_collected_date_unix_timestamp: unixTimestamp,
        origin: 'EMAIL',
        status: 'CREATED',
      },
    };
    const carDates = {
      namespace: 'exponea',
      event_name: 'xsell_car_dates',
      event_properties: {
        UID: uuidv4(),
        xsell_car_month: data.fhins_xsell_car_month,
        xsell_car_collected_date: today,
        xsell_car_collected_date_unix_timestamp: unixTimestamp,
        xsell_car_reg_plate:
          data.fhins_xsell_number_plate?.toUpperCase() || undefined,
        origin: 'EMAIL',
        status: 'CREATED',
      },
    };
    const petDates = {
      namespace: 'exponea',
      event_name: 'xsell_pet_dates',
      event_properties: {
        UID: uuidv4(),
        xsell_pet_month: data.fhins_xsell_pet_month,
        xsell_pet_collected_date: today,
        xsell_pet_collected_date_unix_timestamp: unixTimestamp,
        xsell_pet_name: null,
        origin: 'EMAIL',
        status: 'CREATED',
      },
    };

    if (data.fhins_xsell_home_month) {
      window.dataLayer.push(homeDates);
    }
    if (data.fhins_xsell_car_month) {
      window.dataLayer.push(carDates);
    }
    if (data.fhins_xsell_pet_month) {
      window.dataLayer.push(petDates);
    }

    isSubmitted(true);
  };

  useEffect(() => {
    if (!['home', 'car', 'pet-home', 'pet-car'].includes(product)) {
      navigate('/404/');
    }
  }, [product]);

  useEffect(() => {
    if (product === 'home' && month) {
      reset({ fhins_xsell_home_month: month });
    }
    if (product === 'car' && month) {
      reset({ fhins_xsell_car_month: month });
    }
    if ((product === 'pet-home' || product === 'pet-car') && month) {
      reset({ fhins_xsell_pet_month: month });
    }
  }, [product, month, reset]);

  return (
    <RenewalCaptureContainer>
      <RenewalCaptureContentWrapper>
        {submitted ? (
          <Submission />
        ) : (
          <form onSubmit={handleSubmit(submitDataToDataLayer)}>
            <div>
              <RenewalCaptureHeader>
                Pop your renewal
                <br />
                dates in
              </RenewalCaptureHeader>
              <RenewalCaptureTextWrapper>
                <RenewalCaptureText>
                  Select your renewal dates
                  {product === 'car' ? ' enter your car reg plate' : ' '} and
                  click the pink "Save my details" button below.
                </RenewalCaptureText>
                <RenewalCaptureText>
                  If we can give you a quote, we'll get in touch at the right
                  time. Simple!
                </RenewalCaptureText>
              </RenewalCaptureTextWrapper>
            </div>
            <ProductSectionContainer>
              {product === 'car'
                ? renderProductSection({ product: 'car' })
                : product === 'home'
                ? renderProductSection({ product: 'home' })
                : product === 'pet-car'
                ? renderProductSection({ product: 'pet' })
                : product === 'pet-home' &&
                  renderProductSection({ product: 'pet' })}

              {product === 'car'
                ? renderProductSection({ product: 'pet' })
                : product === 'home'
                ? renderProductSection({ product: 'pet' })
                : product === 'pet-car'
                ? renderProductSection({ product: 'car' })
                : product === 'pet-home' &&
                  renderProductSection({ product: 'home' })}
            </ProductSectionContainer>
            <ProductSectionButton
              type="submit"
              className="renewal-capture-submit"
            >
              Save my details
            </ProductSectionButton>
          </form>
        )}
      </RenewalCaptureContentWrapper>
      {isDesktop && <Shape position={'top-right-renewals'} />}
      {isDesktop && <ShapeX position={'bottom-left-renewals'} />}
    </RenewalCaptureContainer>
  );
};

export default CaptureRenewalsPage;

export const Head: HeadFC = () => (
  <>
    <title>Policy Expert | Insurance Renewal</title>
    <meta name="description" content="Policy Expert Insurance Renewal" />
    <html lang="en" />
  </>
);
