import React from 'react';

import RenewalDropDown from '../renewalDropDown/RenewalDropDown';
import {
  RenderProductSectionContainer,
  RenderProductSectionIcon,
  RenderProductSectionIconContainer,
  RenderProductSectionText,
} from './RafProductSelection.style';

const ProductSection = ({
  id,
  iconSrc,
  iconAlt,
  title,
  dropdownName,
  productType,
  extraContent,
}: {
  id: string;
  iconSrc: string;
  iconAlt: string;
  title: string;
  dropdownName: string;
  productType: string;
  extraContent?: React.ReactNode;
}) => (
  <RenderProductSectionContainer id={id}>
    <RenderProductSectionIconContainer>
      <RenderProductSectionIcon
        src={iconSrc}
        alt={iconAlt}
        $isHome={productType === 'home'}
        $isPet={productType === 'pet'}
      />
    </RenderProductSectionIconContainer>
    <RenderProductSectionText>{title}</RenderProductSectionText>
    <RenewalDropDown
      name={dropdownName}
      required={false}
      product={productType}
    />
    {extraContent}
  </RenderProductSectionContainer>
);

export default ProductSection;
